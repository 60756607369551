.product-right-fixed {
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 9999;
    .nav-close {
        display: block;
        text-align: center;
        width: 90px;
        height: 75px;
        background: #f0f0f0;
        cursor: pointer;
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        @media screen and (max-height: 910px) {
            height: 60px;
            width: 80px;
        }
        @media screen and (max-height: 760px) {
            height: 50px;
            width: 70px;
        }
        @media screen and (max-height: 530px) {
            width: 162px;
        }
        img {
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
        }
        &:hover img {
            opacity: .8;
        }
    }
    .nav-menu {
        text-align: center;
        width: 90px;
        height: 75px;
        background: #c30d23;
        cursor: pointer;
        @media screen and (max-height: 910px) {
            height: 60px;
            width: 80px;
        }
        @media screen and (max-height: 760px) {
            height: 46px;
            width: 70px;
        }
        @media screen and (max-height: 530px) {
            width: 162px;
        }
        img {
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
        }
        &:hover {
            opacity: .8;
        }
    }
    ul {
        width: 91px;
        font-size: 0px;
        @media screen and (max-height: 910px) {
            width: 81px;
        }
        @media screen and (max-height: 760px) {
            width: 71px;
        }
        @media screen and (max-height: 530px) {
            width: 162px;
        }
        li {
            position: relative;
            display: inline-block;
            cursor: pointer;
            width: 90px;
            height: 75px;
            background: #f0f0f0;
            text-align: center;
            font-size: 14px;
            border-bottom: 1px solid #e0e0e0;
            border-left: 1px solid #e0e0e0;
            @media screen and (max-height: 910px) {
                height: 60px;
                width: 80px;
            }
            @media screen and (max-height: 760px) {
                height: 56px;
                width: 70px;
            }
            @media screen and (max-height: 530px) {
                height: 60px;
                width: 80px;
            }
            a {
                padding: 0 4px;
                box-sizing: border-box;
                display: block;
                width: 100%;
                height: 100%;
                color: #333;
            }
            &:hover {
                a {
                    color: $red;
                }
                img {
                    transform: rotateY(360deg);
                }
            }
            img {
                transition: all .8s ease;
                width: 40px;
                margin: 0 auto;
                margin-top: 6px;
                transform: rotateY(0deg);
                @media screen and (max-height: 910px) {
                    width: 30px;
                }
            }
            p,
            h2 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
.notice {
    margin-top: 40px;
    margin-bottom: 40px;
    a {
        cursor: pointer;
        position: relative;
        height: 74px;
        padding-left: 86px;
        padding-right: 200px;
        box-sizing: border-box;
        display: block;
        font-size: 16px;
        color: #666;
        background: #fff;
        line-height: 74px;
        border: 1px solid #fff;
        transition: all .3s ease;
        &:hover {
            border: 1px solid #dbdbdb;
            p {
                color: $red;
            }
        }
        .dn {
            display: none;
        }
        p,
        h2 {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: all .3s ease;
            font-size: 16px;
            line-height: 74px;
            &:before {
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $red;
                display: inline-block;
                margin-right: 14px;
            }
        }
        .icon {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translate(0, -50%);
            width: 25px;
            height: 25px;
            background: url(../images/honor_icon.png);
        }
        span {
            position: absolute;
            right: 90px;
            top: 0px;
            font-size: 14px;
        }
        &:nth-child(odd) {
            border: 1px solid #f8f8f8;
            background: #f8f8f8;
            &:hover {
                border: 1px solid #dbdbdb;
            }
        }
    }
}
.achievement-list {
    margin-top: 50px;
    text-align: center;
    a {
        margin: 0 32px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 470px;
        height: 449px;
        transition: all .4s ease;
        &:hover {
            transform: translate(0, -5px);
            box-shadow: 0 2px 18px rgba(0, 0, 0, 0.1);
        }
        .img {
            padding: 20px;
            background: #fff;
            &-box {
                width: 430px;
                height: 323px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .text {
            height: 86px;
            background: #f7f7f7;
            text-align: center;
            font-size: 18px;
            color: #555;
            padding: 0 20px;
            p,
            h2 {
                line-height: 24px;
                max-height: 48px;
                overflow: hidden;
                position: relative;
                top: 50%;
                font-size: 18px;
                transform: translate(0, -50%);
            }
        }
    }
}
.consult {
    &-top {
        width: 1200px;
        margin: 0 auto;
        background: #fff;
        padding: 20px;
        box-sizing: border-box;
        font-size: 0px;
        .consult-left {
            position: relative;
            font-size: 13px;
            color: #777;
            line-height: 26px;
            display: inline-block;
            vertical-align: top;
            height: 260px;
            width: (1160px - 615px);
            .text {
                display: inline-block;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .iconfont {
                    padding-right: 4px;
                    line-height: 30px;
                    font-size: 30px;
                }
                .iconfont, .text-box {
                    display: inline-block;
                    vertical-align: top;
                }
            }
            .title {
                font-size: 20px;
                color: #333;
            }
        }
        .map2 {
            display: inline-block;
            vertical-align: top;
            width: 615px;
            height: 260px;
        }
    }
    &-bottom {
        width: 1200px;
        margin: 0 auto;
        margin-top: 12px;
        padding: 34px 80px 56px;
        box-sizing: border-box;
        background: #fff;
        .title {
            font-size: 20px;
            color: #555;
        }
        .form-box {
            margin-top: 24px;
            width: 100%;
            font-size: 0px;
            .input-box {
                padding: 0 6px;
                box-sizing: border-box;
                display: inline-block;
                vertical-align: top;
                width: 33.33%;
                height: 50px;
                margin-bottom: 6px;
                .input {
                    position: relative;
                    background: #f8f8f8;
                    width: 100%;
                    height: 100%;
                    padding-left: 100px;
                    box-sizing: border-box;
                    .text {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        line-height: 50px;
                        font-size: 14px;
                        color: #666;
                        padding-left: 20px;
                    }
                    input {
                        border: 0px;
                        font-size: 14px;
                        color: #666;
                        background: none;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .ver-box {
            font-size: 0;
            margin-left: 6px;
            .box {
                display: inline-block;
                vertical-align: top;
                width: 200px;
                background: #f8f8f8;
                height: 50px;
                line-height: 50px;
            }
            .text {
                font-size: 14px;
                color: #666;
                padding-left: 20px;
                display: inline-block;
                vertical-align: top;
                margin-right: 5px;
            }
            input {
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                color: #666;
                width: 120px;
                height: 50px;
                background: none;
                border: none;
                appearance: none;
            }
        }
        .ver-img {
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            height: 50px;
            img {
                display: block;
                height: 50px;
                width: auto;
            }
        }
    }
    &-button {
        width: 1200px;
        margin: 18px auto 0;
        font-size: 0;
        text-align: center;
        .submit {
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            border: 0px;
            width: 195px;
            height: 46px;
            background: #5f5f5f;
            color: #fff;
            font-size: 14px;
            &:hover {
                opacity: .8;
            }
        }
        .reset {
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            border: 0px;
            width: 195px;
            height: 46px;
            background: $red;
            color: #fff;
            font-size: 14px;
            &:hover {
                opacity: .8;
            }
        }
    }
}
.equity {
    width: 1200px;
    margin: 0 auto;
    .stock-con {
        width: 100%;
        margin: 0 auto;
        background-color: #f6f6f6;
        .left {
            float: left;
            width: 350px;
            padding-top: 50px;
            padding-left: 70px;
            h3 {
                display: inline-block;
                font-size: 48px;
                color: $red;
                font-family: 'Arial';
                margin-bottom: 20px;
            }
            .uptxt {
                color: $red;
                float: left;
                font-size: 48px;
                font-family: 'Arial';
                margin-bottom: 20px;
            }
            .downtxt {
                color: #349861;
                float: left;
                font-size: 48px;
                font-family: 'Arial';
                margin-bottom: 20px;
            }
            em {
                vertical-align: top;
                display: inline-block;
                margin-top: 8px;
                margin-left: 7px;
                float: left;
            }
            .up {
                width: 12px;
                height: 11px;
                background-image: url('../images/relation-san.png');
            }
            .down {
                width: 13px;
                height: 8px;
                background-image: url('../images/san-icon.png');
            }
            .name {
                font-size: 14px;
                margin-bottom: 8px;
                color: #666;
                font-weight: bold;
            }
            .num {
                font-size: 14px;
                color: #666;
            }
        }
        .right {
            margin-left: 350px;
            padding: 40px 60px 50px;
            color: #fff;
            background-image: url('../images/relation-bg.png');
            background-size: cover;
            ul {
                padding-bottom: 20px;
                overflow: hidden;
            }
            li {
                float: left;
                width: 25%;
            }
            .num {
                font-family: 'Arial';
                font-size: 36px;
                padding-left: 32px;
            }
            .name {
                font-size: 14px;
                padding-left: 32px;
            }
            .time {
                margin-top: 15px;
                font-size: 16px;
                margin-bottom: 5px;
            }
            .source {
                font-size: 16px;
            }
        }
    }
    .equity-bottom {
        padding-left: 350px;
        height: 605px;
        background: #fff;
        position: relative;
    }
    .equity-left {
        position: absolute;
        top: 0px;
        width: 350px;
        background: #fff;
        left: 0px;
        .equity-img {
            margin: 0 auto;
            width: 306px;
            height: 406px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .equity-btn {
            margin: 0 auto;
            margin-top: 20px;
            width: 246px;
            height: 40px;
            background: url(../images/equity_btn.jpg);
            cursor: pointer;
            display: block;
            &:hover {
                opacity: .8;
            }
        }
    }
    .trend-bd {
        height: 605px;
        margin-bottom: 100px;
        border-left: 2px solid #f4f4f4;
        .trend-top {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 40px;
            background-color: #fff;
            border-bottom: 2px solid #f4f4f4;
            &:hover {
                h3 {
                    color: $red;
                }
            }
            h3 {
                transition: color .3s ease-out;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 18px;
                margin-bottom: 15px;
                font-weight: bold;
                color: #666;
            }
            p {
                color: #777;
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
        .box {
            display: block;
            padding-top: 10px;
            padding-left: 40px;
            padding-bottom: 10px;
            background-color: #fff;
            border-bottom: 2px solid #f4f4f4;
            &:hover {
                h3 {
                    color: $red;
                }
            }
            h3 {
                transition: color .3s ease-out;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #666;
                font-size: 14px;
                margin-bottom: 5px;
                font-weight: bold;
            }
            p {
                font-size: 16px;
                color: #999;
            }
        }
    }
}
.equity-table-con {
    width: 1200px;
    margin: 30px auto 0;
}
.equity-table-title {
    color: $red;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
}
.equity-table {
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 30px;
    th {
        border: 1px solid #ebebeb;
        background: #6c6d6f;
        color: #fff;
        font-size: 14px;
        height: 64px;
        text-align: center;
        vertical-align: middle;
        //+ th {
        //    width: 220px;
        //}
    }
    td {
        border: 1px solid #ebebeb;
        font-size: 14px;
        line-height: 22px;
        color: #888;
        text-align: center;
        vertical-align: middle;
        box-sizing: border-box;
        //padding: 40px 15px;
        height: 50px;
    }
    tr:nth-child(n+5) {
        display: none;
    }
}
.equity-btn {
    display: flex;
    margin: 0 auto;
    width: 310px;
    height: 54px;
    align-items: center;
    justify-content: center;
    line-height: 54px;
    color: #fff;
    background: $red;
    transition: all .2s;
    font-size: 16px;
    cursor: pointer;
    &:hover {
        background: lighten($red, 5%);
    }
    .up {
        display: none;
    }
    .iconfont {
        transform: rotate(180deg);
        margin-right: 5px;
    }
    &.on {
        .iconfont {
            transform: rotate(0);
        }
        .down {
            display: none;
        }
        .up {
            display: block;
        }
    }
}
.govemance-table {
    max-width: 1420px;
    min-width: 1260px;
    margin: 20px auto 50px;
    border: 1px solid #ddd;
    .tr {
        border-bottom: 1px solid #ddd;
        width: 100%;
        background: #fff;
        display: table;
        font-size: 14px;
        color: #777;
        line-height: 36px;
        &:last-child {
            border-bottom: 0px;
        }
        &:nth-child(even) {
            background: #f9f9f9;
        }
        .left {
            display: table-cell;
            width: 460px;
            padding: 14px 50px;
            padding-left: 100px;
            box-sizing: border-box;
        }
        .right {
            border-left: 1px solid #ddd;
            display: table-cell;
            padding: 14px 50px 14px 100px;
            box-sizing: border-box;
            @media screen and (max-width: 1430px) {
                padding: 14px 30px 14px 50px;
            }
            .table {
                font-size: 0px;
            }
            .see {
                font-size: 14px;
                line-height: 36px;
                color: #999;
                margin-left: 90px;
                padding-right: 20px;
                &:hover {
                    color: $red;
                }
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
                &:before {
                    font-size: 22px;
                    line-height: 36px;
                    vertical-align: middle;
                    padding-right: 6px;
                }
            }
            .down {
                position: relative;
                font-size: 14px;
                line-height: 36px;
                color: #999;
                padding-left: 20px;
                &:hover {
                    color: $red;
                }
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
                &:after {
                    content: "";
                    width: 1px;
                    height: 16px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background: #999;
                }
                &:before {
                    font-size: 22px;
                    line-height: 36px;
                    vertical-align: middle;
                    padding-right: 6px;
                }
            }
            .text33 {
                padding-right: 10px;
                box-sizing: border-box;
                font-size: 14px;
                width: 33.33%;
                vertical-align: top;
                display: inline-block;
            }
        }
    }
}
.govemance-list {
    max-width: 1420px;
    min-width: 1260px;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 30px;
    .box {
        margin: 0 -25px;
        font-size: 0px;
        .li {
            padding: 0 25px;
            box-sizing: border-box;
            width: 33.33%;
            display: inline-block;
            vertical-align: top;
            .li-a {
                box-shadow: 1px 1px 4px rgba(0, 0, 0, .04);
                display: block;
                width: 100%;
                transition: all .3s ease;
                &:hover {
                    transform: translate(0, -5px);
                    box-shadow: 1px 1px 10px rgba(0, 0, 0, .04);
                    img {
                        opacity: .8;
                    }
                }
                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (224/444)*100%;
                    overflow: hidden;
                    img {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        transition: all .3s ease;
                    }
                    .title {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background: rgba(0, 0, 0, .5);
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        padding: 0 10px;
                        p {
                            position: relative;
                            top: 50%;
                            transform: translate(0, -50%);
                            font-size: 24px;
                            color: #fff;
                            font-weight: bold;
                            text-align: center;
                        }
                    }
                }
                .text {
                    position: relative;
                    width: 100%;
                    height: 296px;
                    padding-top: 40px;
                    box-sizing: border-box;
                    background: #fff;
                    .p {
                        position: relative;
                        font-size: 12px;
                        line-height: 28px;
                        color: #939393;
                        width: 358px;
                        margin: 0 auto;
                        height: 28*5px;
                        overflow: hidden;
                        padding: 0 16px;
                        box-sizing: border-box;
                        .iScrollVerticalScrollbar {
                            background: #eee;
                            border-radius: 5px;
                            width: 10px !important;
                            .iScrollIndicator {
                                border-radius: 5px !important;
                            }
                        }
                    }
                    .btn {
                        width: 338px;
                        margin: 0 auto;
                        margin-top: 26px;
                        .see {
                            font-size: 14px;
                            line-height: 36px;
                            color: #999;
                            padding-right: 20px;
                            &:hover {
                                color: $red;
                            }
                            span {
                                display: inline-block;
                                vertical-align: middle;
                            }
                            &:before {
                                font-size: 22px;
                                line-height: 36px;
                                vertical-align: middle;
                                padding-right: 6px;
                            }
                        }
                        .down {
                            position: relative;
                            font-size: 14px;
                            line-height: 36px;
                            color: #999;
                            padding-left: 20px;
                            &:hover {
                                color: $red;
                            }
                            span {
                                display: inline-block;
                                vertical-align: middle;
                            }
                            &:after {
                                content: "";
                                width: 1px;
                                height: 16px;
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                background: #999;
                            }
                            &:before {
                                font-size: 22px;
                                line-height: 36px;
                                vertical-align: middle;
                                padding-right: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}