.product-bg {
    box-sizing: border-box;
    padding: 130px 20px;
    background: rgb(230, 231, 233) url(../images/product_bg.jpg) no-repeat center bottom;
    background-size: 1920px auto;
}
.product-wp {
    max-width: 1370px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    .product-address {
        float: left;
        color: #555;
        font-size: 14px;
        line-height: 36px;
        a {
            color: #555;
            &:hover {
                opacity: .8;
            }
        }
        h1 {
            display: inline;
            font-size: 14px;
            line-height: 36px;
            color: #555;
        }
        h2 {
            display: inline;
            font-size: 14px;
            line-height: 20px;
            color: #555;
            &:hover {
                opacity: .8;
            }
        }
    }
    .product-btn {
        float: right;
        background: #fff;
        width: 136px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #666;
        text-align: center;
        &:hover {
            opacity: .8;
            color: $red;
        }
    }
    &.product-box {
        overflow: hidden;
        margin-top: 30px;
        background: #fff;
        border-radius: 20px;
        .bg-gray {
            background-color: #eeeff0;
            overflow: hidden;
        }
    }
    .product-top {
        padding: 70px 0 0;
        box-sizing: border-box;
        @media screen and (max-width: 1400px) {
            padding: 40px 0 0;
        }
        &-box {
            max-width: 1200px;
            margin: 0 auto 70px;
            position: relative;
            @media screen and (max-width: 1400px) {
                margin-bottom: 40px;
            }
        }
        .product-pagination {
            position: absolute;
            right: 20px;
            bottom: 18px;
            z-index: 10;
            text-align: right;
            left: auto;
            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #fff;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                    background: #d7132d;
                }
            }
        }
        .product-swiper {
            position: relative;
            width: 712px;
            height: 470px;
            overflow: hidden;
            float: left;
            background: #fff;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }
        .product-info {
            position: relative;
            width: 405px;
            margin-top: 10px;
            float: right;
            //margin-left: 70px;
            .title {
                font-size: 26px;
                color: #333;
            }
            .product-article {
                position: relative;
                margin-top: 30px;
                margin-bottom: 30px;
                padding-right: 10px;
                box-sizing: border-box;
                height: 310px;
                width: 100%;
                overflow: hidden;
                &-hd {
                    border-bottom: 1px solid #ccc;
                    height: 34px;
                    margin-bottom: 10px;
                    .item {
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                        font-size: 14px;
                        color: #333;
                        margin-right: 40px;
                        cursor: pointer;
                        height: 34px;
                        &.on {
                            &:after {
                                position: absolute;
                                width: 100%;
                                height: 4px;
                                content: '';
                                left: 0;
                                bottom: 0;
                                background: $red;
                            }
                        }
                    }
                }
                &-wp {
                    position: relative;
                    overflow: hidden;
                    height: 266px;
                }
                .iScrollVerticalScrollbar {
                    cursor: pointer;
                    width: 10px !important;
                    border-radius: 5px !important;
                    overflow: hidden;
                    .iScrollIndicator {
                        border-radius: 5px !important;
                        &:hover {
                            opacity: .7;
                        }
                    }
                }
                .li-article {
                    display: none;
                    padding: 14px 0;
                    font-size: 12px;
                    line-height: 26px;
                    color: #777;
                    &:first-child {
                        display: block;
                    }
                }
            }
            .product-btn2 {
                width: 405px;
                height: 52px;
                background: #fff;
                text-align: center;
                a {
                    color: #fff;
                    float: left;
                    width: 50%;
                    height: 52px;
                    line-height: 52px;
                    &:hover {
                        opacity: .8;
                    }
                }
                .red {
                    background: $red;
                }
                .gray {
                    background: #555;
                }
            }
        }
    }
    &>.case-detail-form {
        padding: 80px 0 0;
    }
}
.product-news {
    width: 1200px;
    margin: 0 auto 10px;
    padding-top: 80px;
    &-title {
        font-size: 24px;
        line-height: 64px;
        color: #333;
        position: relative;
        text-align: center;
        margin-bottom: 50px;
        &:after {
            position: absolute;
            width: 100%;
            top: 50%;
            border-top: 1px solid #d6d7d8;
            content: '';
            left: 0;
        }
        .t {
            display: inline-block;
            vertical-align: top;
            padding: 0 30px;
            background-color: #eeeff0;
            position: relative;
            z-index: 2;
            font-size: 28px;
            color: #333;
            line-height: 64px;
        }
    }
    &-list {
        font-size: 0;
        li {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;
            margin-bottom: 12px;
            &:nth-child(2n+1) {
                padding-right: 6px;
            }
            &:nth-child(2n) {
                padding-left: 6px;
            }
            a {
                background-color: #fff;
                display: block;
                padding: 20px 50px 30px;
                &:hover {
                    .t {
                        color: $red;
                    }
                }
            }
            .top {
                border-bottom: 1px dashed #d8d8d8;
                display: flex;
                width: 100%;
                overflow: hidden;
                margin-bottom: 10px;
            }
            .t {
                flex: 1;
                overflow: hidden;
                padding-left: 30px;
                font-size: 18px;
                color: #333;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 50px;
                position: relative;
                transition: all .2s;
                &:before {
                    font-family: 'iconfont';
                    content: '\e6cc';
                    color: $red;
                    font-size: 22px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    line-height: 50px;
                }
            }
            .time {
                font-size: 14px;
                color: #333;
                margin-left: 10px;
                line-height: 50px;
            }
            .p {
                font-size: 13px;
                line-height: 24px;
                color: #777;
                height: 48px;
                overflow: hidden;
            }
        }
    }
}
.product-recommend {
    width: 1200px;
    margin: 50px auto;
    &-title {
        font-size: 24px;
        line-height: 64px;
        color: #333;
        position: relative;
        text-align: center;
        &:after {
            position: absolute;
            width: 100%;
            top: 50%;
            border-top: 1px solid #e5e5e5;
            content: '';
            left: 0;
        }
        .t {
            display: inline-block;
            vertical-align: top;
            padding: 0 30px;
            background-color: #fff;
            position: relative;
            z-index: 2;
            font-size: 28px;
            color: #333;
            line-height: 64px;
        }
    }
    &-swiper {
        margin-top: 38px;
        position: relative;
        padding: 0 50px;
        .box {
            width: 100%;
            overflow: hidden;
        }
        .product-prev {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            transform: translate(0, -70%);
            cursor: pointer;
            left: 0px;
            background: url(../images/product_icon.png);
            &:hover {
                opacity: .7;
            }
        }
        .product-next {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translate(0, -70%) rotate(180deg);
            cursor: pointer;
            background: url(../images/product_icon.png);
            &:hover {
                opacity: .7;
            }
        }
        .swiper-slide {
            width: 20%;
            box-sizing: border-box;
            padding: 0 7px;
            a {
                display: block;
                width: 100%;
                &:hover {
                    img {
                        transform: scale(1.06);
                        opacity: .9;
                    }
                    .p {
                        color: $red;
                    }
                }
                .img {
                    width: 100%;
                    padding-bottom: (476/720) * 100%;
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 10px;
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transition: all .3s ease;
                        object-fit: cover;
                    }
                }
                .p {
                    font-size: 14px;
                    color: #666;
                    line-height: 32px;
                }
            }
        }
    }
}
.product-bottom {
    position: relative;
    background: #eeeff0;
    width: 100%;
    text-align: center;
    padding: 30px 20px 0;
    font-size: 14px;
    overflow: hidden;
    box-sizing: border-box;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    .product-bottom-prev {
        cursor: pointer;
        position: absolute;
        top: 56px;
        left: 74px;
        z-index: 10;
        width: 14px;
        height: 25px;
        background: url(../images/year_icon_1.png) no-repeat;
        background-size: 100% 100%;
    }
    .product-bottom-next {
        cursor: pointer;
        position: absolute;
        top: 56px;
        right: 74px;
        z-index: 10;
        width: 14px;
        height: 25px;
        transform: rotate(180deg);
        background: url(../images/year_icon_1.png) no-repeat;
        background-size: 100% 100%;
    }
    &-box {
        overflow: hidden;
        max-width: 120 * 9px;
        position: relative;
        display: inline-block;
        vertical-align: top;
    }
    .li {
        width: 120px;
        display: inline-block;
        vertical-align: top;
        padding: 0 30px 30px;
        box-sizing: border-box;
        &:hover {
            opacity: .8;
            color: $red;
        }
        img {
            width: 40px;
            height: 40px;
        }
        p {
            margin-top: 14px;
        }
    }
}
.product-topic {
    &-banner {
        min-width: 1300px;
        position: relative;
        width: 100%;
        padding-bottom: (810/1920) * 100%;
        overflow: hidden;
        .swiper-slide {
            display: block;
        }
        &-box {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            .img {
                width: 100%;
                height: 100%;
                background: #fff none no-repeat center center;
                background-size: cover;
                img {
                    display: block;
                    width: 100%;
                }
            }
        }
        .product-topic-pagination {
            position: absolute;
            bottom: 156px;
            left: 0px;
            width: 100%;
            text-align: center;
            z-index: 20;
            span {
                width: 10px;
                height: 10px;
                opacity: 1;
                background: #828282;
                &.swiper-pagination-bullet-active {
                    background: $red;
                }
            }
        }
    }
}
.product-info-top {
    position: relative;
    z-index: 10;
    height: 145px;
    width: 1210px;
    margin: -145px auto 0;
    .product-address {
        text-align: right;
        font-size: 13px;
        line-height: 38px;
        color: #333;
        a {
            color: #333;
            &:hover {
                color: $red;
            }
        }
        h1 {
            display: inline;
            color: #333;
            font-size: 13px;
            line-height: 38px;
        }
    }
    &-left {
        float: left;
        width: 220px;
        height: 145px;
        background: #828282;
        text-align: center;
        color: #fff;
        font-size: 22px;
        padding-top: 30px;
        box-sizing: border-box;
        img {
            width: 50px;
        }
        p,
        h2 {
            margin-top: 10px;
            color: #fff;
            font-size: 22px;
        }
    }
    &-right {
        float: left;
        width: 990px;
        height: 145px;
        background: rgba(255, 255, 255, .9);
        .text {
            font-size: 13px;
            color: #555;
            line-height: 30px;
            width: 890px;
            margin: 0 auto;
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
}
.product-switch-con {
    .product-nav {
        display: none;
        &:first-child {
            display: block;
        }
    }
}
.product-list-con {
    background-color: #f0f0f0;
}
.product-nav {
    position: relative;
    font-size: 0;
    padding: 26px 0;
    max-width: 1210px;
    margin: 0 auto;
    text-align: left;
    .next {
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 30px;
        transform: rotate(180deg);
        width: 14px;
        height: 25px;
        background: url(../images/year_icon_1.png) no-repeat;
    }
    .prev {
        cursor: pointer;
        position: absolute;
        left: 0px;
        top: 30px;
        width: 14px;
        height: 25px;
        background: url(../images/year_icon_1.png) no-repeat;
    }
    .product-nav-swiper {
        margin: 0 auto;
        width: 158 * 7px;
        overflow: hidden;
        position: relative;
        .swiper-slide {
            &:nth-child(8) {
                border-left: 1px solid #dcdcdc;
            }
        }
    }
    .swiper-slide {
        text-align: center;
        margin-bottom: 30px;
        width: 151px;
        height: 78px;
        display: inline-block;
        cursor: pointer;
        &.on,
        &:hover {
            .img, p, h2 {
                opacity: .7;
                color: $red;
                a {
                    color: $red;
                }
            }
        }
        .img {
            margin-bottom: 16px;
            display: inline-block;
            width: 40px;
            height: 40px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        p,
        h2 {
            font-size: 15px;
            color: #333;
            a {
                color: #333;
            }
        }
        + .swiper-slide {
            box-sizing: border-box;
            border-left: 1px solid #dcdcdc;
        }
        &:nth-child(8n+1) {
            border-left: none;
        }
    }
}
.product-info2 {
    margin: 0 auto;
    margin-bottom: 98px;
    height: 554px;
    width: 1428px;
    display: none;
    //&:first-child {
    //    display: block;
    //}
    @media screen and (max-width: 1530px) {
        width: 1300px;
    }
    &-left {
        width: 1004px;
        height: 554px;
        float: left;
        @media screen and (max-width: 1530px) {
            width: 904px;
        }
        .product-li {
            display: none;
            width: 100%;
            height: 100%;
            &.on {
                display: block;
            }
            &-pagination {
                position: absolute;
                bottom: 20px;
                left: 0px;
                width: 100%;
                z-index: 10;
                text-align: center;
                span {
                    width: 8px;
                    height: 8px;
                    opacity: 1;
                    background: #fff;
                    &.swiper-pagination-bullet-active {
                        background: $red;
                    }
                }
            }
            &-img-swiper {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
            .img {
                width: 100%;
                height: 100%;
                display: block;
                background: #fff none no-repeat center center;
                background-size: cover;
                transition: all .3s ease;
                position: relative;
                &:hover {
                    opacity: .9;
                }
                img {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }
            .more, .title, .p {
                display: none;
            }
        }
    }
    &-right {
        position: relative;
        float: left;
        background: #828282;
        width: 424px;
        height: 554px;
        color: #fff;
        @media screen and (max-width: 1530px) {
            width: 390px;
        }
        .text {
            width: 280px;
            margin: 0 auto;
            font-size: 14px;
            line-height: 36px;
            .title {
                line-height: 36px;
                height: 72px;
                overflow: hidden;
                color: #fff;
                display: block;
                font-size: 30px;
                margin-bottom: 20px;
                margin-top: 112px;
                &:hover {
                    opacity: .8;
                }
            }
            .p {
                color: #fff;
                display: block;
                line-height: 36px;
                height: 36 * 4px;
                overflow: hidden;
                &:hover {
                    opacity: .8;
                }
            }
        }
        .more {
            position: absolute;
            bottom: 150px;
            left: 182px;
            vertical-align: top;
            cursor: pointer;
            display: inline-block;
            width: 100px;
            height: 40px;
            border: 1px solid #9d9d9d;
            border-radius: 2px;
            font-size: 12px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            &:hover {
                background: #9d9d9d;
            }
        }
        .btn {
            -webkit-user-select: none;
            user-select: none;
            height: 42px;
            position: absolute;
            bottom: 150px;
            left: 90px;
            font-size: 0px;
            > div {
                margin-right: 4px;
            }
            .prev {
                vertical-align: top;
                display: inline-block;
                cursor: pointer;
                border-radius: 2px;
                width: 40px;
                height: 40px;
                border: 1px solid #9d9d9d;
                background: url(../images/produt_icon_1.png) no-repeat center center;
                &:hover {
                    background-color: #9d9d9d;
                }
            }
            .next {
                vertical-align: top;
                display: inline-block;
                transform: rotate(180deg);
                cursor: pointer;
                border-radius: 2px;
                width: 40px;
                height: 40px;
                border: 1px solid #9d9d9d;
                background: url(../images/produt_icon_1.png) no-repeat center center;
                &:hover {
                    background-color: #9d9d9d;
                }
            }
        }
    }
}
.product-bottom2 {
    background: #f0f0f0;
    padding-bottom: 96px;
    padding-top: 70px;
    .product-swiper {
        overflow: hidden;
        width: 100%;
    }
    &-nav {
        margin-top: 20px;
        text-align: center;
        font-size: 18px;
        color: #666;
        font-weight: bold;
        .a {
            cursor: pointer;
            display: inline-block;
            padding: 0 14px;
            color: #666;
            text-align: center;
            font-size: 18px;
            color: #666;
            font-weight: bold;
            &.on {
                color: $red;
            }
            + .a {
                position: relative;
                &:hover {
                    color: $red;
                }
                &:after {
                    position: absolute;
                    top: 50%;
                    left: 0px;
                    margin-top: -4px;
                    content: '';
                    width: 1px;
                    height: 10px;
                    background: #666;
                }
            }
        }
    }
    &-list {
        position: relative;
        display: none;
        text-align: center;
        font-size: 0;
        margin: 34px auto 0;
        max-width: 1500px;
        min-width: 1300px;
        .product-pagination {
            margin-top: 30px;
            position: relative;
            text-align: center;
            span {
                width: 11px;
                height: 11px;
                background: #828282;
                opacity: 1;
                margin: 0 4px;
                &.swiper-pagination-bullet-active {
                    background: $red;
                }
            }
        }
        &.on {
            display: block;
        }
        .li {
            padding: 0 10px;
            box-sizing: border-box;
            width: 25%;
            display: inline-block;
            .a {
                cursor: pointer;
                position: relative;
                display: block;
                width: 100%;
                &:hover {
                    .text {
                        opacity: .7;
                    }
                    &:before {
                        opacity: .7;
                        top: 130px;
                        transform: translate(-50%, 0) rotate(180deg);
                    }
                }
                &:before {
                    position: absolute;
                    z-index: 10;
                    top: 150px;
                    left: 50%;
                    transition: all .5s ease;
                    transform: translate(-50%, 0) rotate(0deg);
                    content: "";
                    width: 30px;
                    height: 30px;
                    background: url(../images/product_icon_5.png);
                    background-size: 100% 100%;
                    @media screen and (max-width: 1530px) {
                        top: 130px;
                    }
                }
                &:after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background: rgba(0, 0, 0, .5);
                }
                .img {
                    display: block;
                    position: relative;
                    width: 100%;
                    padding-bottom: (233/354) * 100%;
                    background: #fff none no-repeat center center;
                    background-size: cover;
                    img {
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }
                .text {
                    transition: all .5s ease;
                    z-index: 10;
                    position: absolute;
                    top: 74px;
                    left: 0px;
                    width: 100%;
                    padding: 0 10px;
                    box-sizing: border-box;
                    text-align: center;
                    font-size: 16px;
                    color: #fff;
                    .p {
                        display: none;
                    }
                }
            }
        }
    }
}
.product-show-win {
    position: fixed;
    display: none;
    z-index: 10000;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    .product-close {
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 35px;
        height: 35px;
        background: url(../images/product_close.png);
        &:hover {
            opacity: .7;
        }
    }
    .article {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .img {
        float: left;
        width: 300px;
        height: 190px;
        background: #fff none no-repeat center center;
        background-size: cover;
        margin-right: 40px;
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
        }
    }
    .text {
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        overflow: hidden;
        p,
        h2 {
            font-size: 24px;
            color: #333;
        }
        .p {
            margin-top: 20px;
            color: #666;
            font-size: 13px;
            line-height: 24px;
            // max-height: 100px;
            overflow: hidden;
            height: 120px;
            position: relative;
            padding-right: 14px;
            .iScrollVerticalScrollbar {
                background: #eee;
                border-radius: 5px;
                width: 10px !important;
                .iScrollIndicator {
                    border-radius: 5px !important;
                }
            }
        }
        .article-text {
            margin-top: 30px;
            border-top: 1px dashed #999;
            padding-top: 30px;
            .p {
                line-height: 30px;
                margin-top: 0px;
            }
        }
    }
    .product-win2 {
        box-shadow: 2px 3px 51px rgba(2, 2, 2, .41);
        border: solid 8px rgba(2, 2, 2, .36);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1060px;
        height: 470px;
        background: #fff;
        padding: 50px;
        padding-right: 46px;
        box-sizing: border-box;
        .img {
            width: 494px;
            height: 360px;
            position: relative;
            img {
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .product-win {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 900px;
        height: 234px;
        background: #fff;
        padding: 20px;
        padding-right: 46px;
        box-sizing: border-box;
    }
}
.picture-list {
    width: 1300px;
    margin: 0 auto;
    padding-bottom: 40px;
    font-size: 0px;
    .li {
        width: 20%;
        display: inline-block;
        vertical-align: top;
        padding: 0 10px;
        box-sizing: border-box;
        a {
            margin-bottom: 16px;
            border: 1px solid #e1e1e1;
            box-sizing: border-box;
            width: 100%;
            display: block;
            transition: all .3s ease;
            &:hover {
                transform: translate(0, -5px);
                box-shadow: 0 2px 18px rgba(0, 0, 0, .1);
            }
            .img {
                position: relative;
                width: 100%;
                padding-bottom: (332/236) * 100%;
                img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                position: relative;
                font-size: 15px;
                color: #555;
                background: #f7f7f7;
                height: 54px;
                text-align: center;
                p,
                h2 {
                    font-size: 15px;
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }
    }
}
.sitemap-bg {
    box-sizing: border-box;
    padding: 170px 20px 130px;
}
.sitemap-con {
    max-width: 1370px;
    font-size: 0;
    margin: 0 auto;
    padding: 0 10px;
    .sitemap-col-3 {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        box-sizing: border-box;
        padding: 20px 30px;
    }
    dl {
        dt {
            font-size: 20px;
            padding: 10px 0 5px;
            line-height: 1.6;
            text-align: left;
            a {
                color: $red;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        dd {
            padding: 2px 0 2px 15px;
            background: url(../images/dot-gray.png) no-repeat left 8px;
            font-size: 14px;
            line-height: 1.6;
            text-align: left;
            a {
                color: #333;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
.product-main {
    background: #f0f0f0;
    padding-bottom: 100px;
    padding-top: 40px;
    &-list {
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            box-sizing: border-box;
            margin-bottom: 16px;
            &:nth-child(2n+1) {
                padding-right: 10px;
            }
            &:nth-child(2n) {
                padding-left: 10px;
            }
            &:nth-child(4n+3),
            &:nth-child(4n+4) {
                .item {
                    flex-direction: row-reverse;
                }
            }
            .item {
                display: flex;
                width: 100%;
                overflow: hidden;
                align-items: center;
                background-color: #fff;
                &:hover {
                    .img img {
                        transform: scale(1.1);
                    }
                    .t {
                        color: $red;
                        a {
                            color: $red;
                        }
                    }
                }
            }
            .img {
                width: 356px;
                height: 246px;
                overflow: hidden;
                display: block;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all .5s;
                }
                @media screen and (max-width: 1600px) {
                    width: 300px;
                    height: 208px;
                }
            }
            .info {
                flex: 1;
                overflow: hidden;
                box-sizing: border-box;
                padding: 0 40px;
            }
            .t {
                font-size: 20px;
                color: #333;
                line-height: 24px;
                max-height: 48px;
                overflow: hidden;
                margin-bottom: 8px;
                a {
                    color: #333;
                }
            }
            .p {
                font-size: 16px;
                line-height: 24px;
                max-height: 72px;
                overflow: hidden;
                color: #666;
                margin-bottom: 10px;
            }
            .arrow {
                background: $red;
                color: #fff;
                width: 26px;
                height: 26px;
                border-radius: 100%;
                text-align: center;
                line-height: 26px;
                font-size: 12px;
            }
        }
    }
}
.product-bread {
    width: 1210px;
    margin: 0 auto;
    padding-top: 20px;
}
.product-list-box {
    .swiper-slide {
        .item {
            display: flex;
            background-color: #fff;
            margin-bottom: 15px;
            overflow: hidden;
            &:nth-child(2n) {
                flex-direction: row-reverse;
            }
            &:hover {
                .img span img {
                    transform: scale(1.05);
                }
                .t {
                    color: $red;
                }
            }
        }
        .img {
            flex: 1;
            overflow: hidden;
            span {
                display: block;
                width: 100%;
                padding-top: (476/720) * 100%;
                position: relative;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all .5s;
                }
            }
        }
        .info {
            flex: 1;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .wrapper {
            width: 460px;
            max-width: 100%;
        }
        .t {
            font-size: 30px;
            font-weight: 800;
            color: #333;
            margin-bottom: 20px;
            transition: all .2s;
        }
        .p {
            border-top: 1px dashed #d6d6d6;
            padding-top: 20px;
            font-size: 14px;
            line-height: 30px;
            color: #777;
            margin-bottom: 20px;
            max-height: 150px;
            overflow: hidden;
        }
        .btn {
            width: 95px;
            height: 71px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .swiper-btn {
        text-align: center;
        position: relative;
        z-index: 10;
        padding-top: 20px;
        .btn {
            display: inline-block;
            vertical-align: top;
            width: 170px;
            height: 44px;
            text-align: center;
            line-height: 44px;
            background-color: #fff;
            color: #9f9f9f;
            transition: all .2s;
            font-size: 18px;
            cursor: pointer;
            &.prev {
                transform: rotate(180deg);
            }
            &:hover {
                background: $red;
                color: #fff;
            }
        }
    }
}
.product-article-con {
    padding-top: 36px;
    max-width: 1200px;
    margin: 0 auto 70px;
    .hd {
        //display: flex;
        margin-bottom: 20px;
        font-size: 0;
        height: 90px;
        .wrapper {
            font-size: 0;
            padding: 20px 0;
            &.fixed {
                position: fixed;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 100;
                background-color: #fff;
            }
        }
        .item {
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            //flex: 1;
            position: relative;
            text-align: center;
            span {
                display: inline-block;
                vertical-align: top;
                max-width: 100%;
                width: 230px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 16px;
                color: #333;
                cursor: pointer;
                transition: all .2s;
                &:hover {
                    color: #fff;
                    background: $red;
                }
            }
            &.on {
                span {
                    color: #fff;
                    background: $red;
                }
            }
            &:after {
                position: absolute;
                width: 1px;
                height: 20px;
                right: 0;
                top: 50%;
                margin-top: -10px;
                background: #bfbfbf;
                content: '';
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
    .bd {
        .article {
            font-size: 12px;
            line-height: 24px;
            color: rgba(#5e5e5e, .8);
            p {
                margin-bottom: 20px;
            }
        }
    }
}
.product-list-icon-swiper {
    display: flex;
    overflow: hidden;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 50px;
    .prev,
    .next {
        width: 30px;
        color: #555;
        font-size: 30px;
        cursor: pointer;
        line-height: 30px;
        margin-top: 35px;
        transition: all .2s;
        &:hover {
            color: $red;
        }
    }
    .prev {
        transform: rotate(180deg);
        //margin-top: 0;
    }
    .swiper-container {
        flex: 1;
        &.center {
            .swiper-wrapper {
                justify-content: center;
            }
        }
        .swiper-slide {
            a {
                display: block;
            }
            .img {
                position: relative;
                width: 100px;
                height: 100px;
                display: flex;
                margin: 0 auto 10px;
                border-radius: 100%;
                background-color: #fff;
                align-items: center;
                justify-content: center;
                img {
                    display: block;
                    width: 40px;
                    height: 40px;
                }
                &:after {
                    position: absolute;
                    background: $red;
                    content: '';
                    width: 12px;
                    height: 4px;
                    display: block;
                    left: 50%;
                    margin-left: -6px;
                    bottom: 16px;
                }
            }
            .t {
                text-align: center;
                font-size: 14px;
                color: #555;
            }
            &:hover,
            &.on {
                .t {
                    color: $red;
                }
            }
        }
    }
}
.product-list-swiper {
    .swiper-slide {
        font-size: 0;
        .item {
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            box-sizing: border-box;
            margin-bottom: 30px;
            &:nth-child(3n+1) {
                padding-right: 18px;
            }
            &:nth-child(3n+2) {
                padding-right: 9px;
                padding-left: 9px;
            }
            &:nth-child(3n+3) {
                padding-left: 18px;
            }
            .wrapper {
                display: block;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                overflow: hidden;
                background-color: #fff;
            }
            .img {
                width: 100%;
                position: relative;
                padding-top: (307/463) * 100%;
                overflow: hidden;
                display: block;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    transition: all .5s;
                }
            }
            .info {
                padding: 25px 40px 40px;
                position: relative;
            }
            .btn {
                width: 46px;
                height: 46px;
                text-align: center;
                line-height: 46px;
                color: #fff;
                background: $red;
                position: absolute;
                right: 0;
                top: 0;
                margin-top: -23px;
                z-index: 5;
                font-size: 22px;
                font-weight: bold;
            }
            .t {
                font-size: 22px;
                color: #333;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 14px;
                transition: all .2s;
                a {
                    color: #333;
                }
            }
            .p {
                border-top: 1px dashed #8f8f8f;
                padding-top: 14px;
                font-size: 14px;
                line-height: 24px;
                height: 48px;
                overflow: hidden;
                color: #666;
            }
            &:hover {
                .img img {
                    transform: scale(1.05);
                }
                .t {
                    color: $red;
                    a {
                        color: $red;
                    }
                }
            }
        }
    }
    .pagination-btn {
        text-align: center;
        padding-top: 20px;
        .btn {
            display: inline-block;
            vertical-align: top;
            width: 170px;
            height: 44px;
            text-align: center;
            line-height: 44px;
            background-color: #fff;
            cursor: pointer;
            font-size: 18px;
            color: #9f9f9f;
            transition: all .2s;
            &:hover {
                background: $red;
                color: #fff;
            }
            &.prev {
                transform: rotate(180deg);
            }
        }
    }
}
.map-con {
    width: 1100px;
    max-width: 100%;
    margin: 0 auto 30px;
    display: flex;
    align-items: flex-start;
}
.map-word {
    flex: 1;
    overflow: hidden;
    margin-right: 50px;
    position: relative;
    padding-bottom: 44px;
    padding-top: 85px;
    &:before {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        display: block;
        width: 25px;
        height: 2px;
        background: #e60012;
    }
    .t {
        font-size: 28px;
        color: #000;
        margin-bottom: 10px;
    }
    .s {
        font-family: Arial;
        font-size: 36px;
        color: #272727;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .p {
        font-size: 12px;
        color: #929292;
    }
}
.map-box {
    position: relative;
    width: 602px;
    height: 432px;
    background: url(../images/map_bg_2.png) center no-repeat;
    > * {
        display: block;
        position: absolute;
        z-index: 1;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .ah {
        width: 52px;
        height: 63px;
        top: 227px;
        left: 388px;
        &:hover,
        &.on {
            background-image: url(../images/maps/ah.png);
        }
    }
    .bj {
        width: 17px;
        height: 18px;
        top: 154px;
        left: 385px;
        &:hover,
        &.on {
            background-image: url(../images/maps/bj.png);
        }
    }
    .chq {
        width: 51px;
        height: 47px;
        top: 265px;
        left: 289px;
        &:hover,
        &.on {
            background-image: url(../images/maps/chq.png);
        }
    }
    .gd {
        width: 81px;
        height: 63px;
        top: 342px;
        left: 338px;
        &:hover,
        &.on {
            background-image: url(../images/maps/gd.png);
        }
    }
    .gs {
        width: 148px;
        height: 123px;
        top: 138px;
        left: 178px;
        &:hover,
        &.on {
            background-image: url(../images/maps/gs.png);
        }
    }
    .gx {
        width: 80px;
        height: 59px;
        top: 333px;
        left: 281px;
        &:hover,
        &.on {
            background-image: url(../images/maps/gx.png);
        }
    }
    .gz {
        width: 62px;
        height: 52px;
        top: 302px;
        left: 275px;
        &:hover,
        &.on {
            background-image: url(../images/maps/gz.png);
        }
    }
    .hainan {
        width: 25px;
        height: 21px;
        top: 407px;
        left: 327px;
        &:hover,
        &.on {
            background-image: url(../images/maps/hainan.png);
        }
    }
    .hb {
        width: 82px;
        height: 49px;
        top: 250px;
        left: 322px;
        &:hover,
        &.on {
            background-image: url(../images/maps/hb.png);
        }
    }
    .heb {
        width: 58px;
        height: 81px;
        top: 135px;
        left: 366px;
        &:hover,
        &.on {
            background-image: url(../images/maps/heb.png);
        }
    }
    .fj {
        width: 47px;
        height: 59px;
        top: 304px;
        left: 403px;
        &:hover,
        &.on {
            background-image: url(../images/maps/fj.png);
        }
    }
    .hen {
        width: 62px;
        height: 56px;
        top: 213px;
        left: 340px;
        &:hover,
        &.on {
            background-image: url(../images/maps/hen.png);
        }
    }
    .hlj {
        width: 116px;
        height: 106px;
        top: 2px;
        left: 409px;
        &:hover,
        &.on {
            background-image: url(../images/maps/hlj.png);
        }
    }
    .hun {
        width: 56px;
        height: 65px;
        top: 288px;
        left: 328px;
        &:hover,
        &.on {
            background-image: url(../images/maps/hun.png);
        }
    }
    .jl {
        width: 88px;
        height: 59px;
        top: 86px;
        left: 426px;
        &:hover,
        &.on {
            background-image: url(../images/maps/jl.png);
        }
    }
    .js {
        width: 56px;
        height: 50px;
        top: 222px;
        left: 399px;
        &:hover,
        &.on {
            background-image: url(../images/maps/js.png);
        }
    }
    .jx {
        width: 51px;
        height: 67px;
        top: 285px;
        left: 378px;
        &:hover,
        &.on {
            background-image: url(../images/maps/jx.png);
        }
    }
    .ln {
        width: 61px;
        height: 53px;
        top: 120px;
        left: 411px;
        &:hover,
        &.on {
            background-image: url(../images/maps/ln.png);
        }
    }
    .nmg {
        width: 226px;
        height: 196px;
        top: 6px;
        left: 223px;
        &:hover,
        &.on {
            background-image: url(../images/maps/nmg.png);
        }
    }
    .nx {
        width: 34px;
        height: 47px;
        top: 180px;
        left: 280px;
        &:hover,
        &.on {
            background-image: url(../images/maps/nx.png);
        }
    }
    .qh {
        width: 132px;
        height: 96px;
        top: 173px;
        left: 137px;
        &:hover,
        &.on {
            background-image: url(../images/maps/qh.png);
        }
    }
    .sc {
        width: 111px;
        height: 96px;
        top: 241px;
        left: 211px;
        &:hover,
        &.on {
            background-image: url(../images/maps/sc.png);
        }
    }
    .sd {
        width: 69px;
        height: 45px;
        top: 187px;
        left: 385px;
        &:hover,
        &.on {
            background-image: url(../images/maps/sd.png);
        }
    }
    .shx {
        width: 55px;
        height: 93px;
        top: 177px;
        left: 293px;
        &:hover,
        &.on {
            background-image: url(../images/maps/shx.png);
        }
    }
    .sx {
        width: 37px;
        height: 73px;
        top: 162px;
        left: 338px;
        &:hover,
        &.on {
            background-image: url(../images/maps/sx.png);
        }
    }
    .tj {
        width: 15px;
        height: 20px;
        top: 165px;
        left: 397px;
        &:hover,
        &.on {
            background-image: url(../images/maps/tj.png);
        }
    }
    .tw {
        width: 17px;
        height: 40px;
        top: 336px;
        left: 451px;
        &:hover,
        &.on {
            background-image: url(../images/maps/tw.png);
        }
    }
    .xj {
        width: 208px;
        height: 156px;
        top: 51px;
        left: 3px;
        &:hover,
        &.on {
            background-image: url(../images/maps/xj.png);
        }
    }
    .xz {
        width: 200px;
        height: 124px;
        top: 196px;
        left: 26px;
        &:hover,
        &.on {
            background-image: url(../images/maps/xz.png);
        }
    }
    .yn {
        width: 92px;
        height: 92px;
        top: 303px;
        left: 207px;
        &:hover,
        &.on {
            background-image: url(../images/maps/yn.png);
        }
    }
    .zj {
        width: 40px;
        height: 50px;
        top: 264px;
        left: 424px;
        &:hover,
        &.on {
            background-image: url(../images/maps/zj.png);
        }
    }
    .sh {
        width: 50px;
        height: 30px;
        top: 249px;
        left: 458px;
    }
}
.map-select-con {
    display: flex;
    width: 1100px;
    max-width: 100%;
    margin: 0 auto;
    .select {
        width: 295px;
        margin-right: 16px;
        border: 1px solid $red;
        background: #fff;
        position: relative;
        select {
            display: block;
            width: 100%;
            height: 42px;
            line-height: 42px;
            font-size: 12px;
            color: #333;
            box-sizing: border-box;
            padding: 0 20px;
            border: none;
            background: none;
            outline: none;
        }
        &:after {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translate(0, -50%);
            content: "";
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 6px solid #000;
        }
    }
    .input-con {
        flex: 1;
        overflow: hidden;
        border: 1px solid $red;
        background: #fff;
        display: flex;
    }
    button {
        width: 50px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        display: block;
        background: $red;
        border: none;
        appearance: none;
        cursor: pointer;
        color: #fff;
        font-size: 20px;
    }
    .input {
        flex: 1;
        overflow: hidden;
        input {
            width: 100%;
            display: block;
            height: 42px;
            line-height: 42px;
            font-size: 12px;
            box-sizing: border-box;
            padding: 0 15px;
            color: #333;
            appearance: none;
            border: none;
            background: none;
        }
    }
}
.map-list-con {
    background-color: #fff;
    padding: 50px 0;
}
.map-tags {
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
}
.map-list {
    font-size: 0;
    li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        box-sizing: border-box;
        margin-bottom: 38px;
        &:nth-child(3n+1) {
            padding-right: 20px;
        }
        &:nth-child(3n+2) {
            padding-right: 10px;
            padding-left: 10px;
        }
        &:nth-child(3n+3) {
            padding-left: 20px;
        }
    }
    .wrapper {
        display: block;
        padding: 12px;
        background: #f5f5f5;
        &:hover {
            .img img {
                transform: scale(1.1);
            }
            .t {
                color: $red;
            }
        }
    }
    .img {
        width: 100%;
        padding-top: percentage(248/398);
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
            transition: all .6s;
        }
    }
    .info {
        padding: 15px 24px;
    }
    .t {
        font-size: 18px;
        color: #000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 15px;
        transition: all .2s;
    }
    .address {
        position: relative;
        padding-left: 20px;
        font-size: 14px;
        color: #6a6a6a;
        line-height: 22px;
        text-align: left;
        bottom: auto;
        margin-bottom: 8px;
        height: 22px;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            line-height: 22px;
            font-family: 'iconfont';
            content: '\e69a';
            color: $red;
        }
        &:empty {
            &:before {
                display: none;
            }
        }
    }
    .phone {
        position: relative;
        padding-left: 20px;
        font-size: 14px;
        color: #6a6a6a;
        line-height: 22px;
        //font-style: italic;
        text-align: left;
        height: 22px;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            line-height: 22px;
            font-family: 'iconfont';
            content: '\e6f5';
            color: $red;
            font-style: normal;
        }
        &:empty {
            &:before {
                display: none;
            }
        }
    }
}
.map-outside-con {
    display: flex;
    margin-bottom: 60px;
    align-items: flex-start;
}
.map-outside-word {
    flex: 1;
    overflow: hidden;
    padding-top: 155px;
    position: relative;
    padding-bottom: 44px;
    &:before {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        display: block;
        width: 25px;
        height: 2px;
        background: #e60012;
    }
    .t {
        font-size: 36px;
        color: #272727;
        line-height: 46px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    .p {
        font-size: 14px;
        color: #929292;
    }
}
.map-outside-box {
    width: 922px;
    height: 552px;
    position: relative;
    background-image: url(../images/map_bg_3.png);
    background-position: center center;
    background-repeat: no-repeat;
    > * {
        display: block;
        position: absolute;
        z-index: 1;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .bmz {
        width: 711px;
        height: 262px;
        top: 2px;
        left: 120px;
        &:hover,
        &.on {
            background-image: url(../images/bmz.png);
        }
    }
    .dyz {
        width: 204px;
        height: 177px;
        top: 286px;
        left: 352px;
        &:hover,
        &.on {
            background-image: url(../images/dyz.png);
        }
    }
    .fz {
        width: 167px;
        height: 284px;
        top: 144px;
        left: 22px;
        &:hover,
        &.on {
            background-image: url(../images/fz.png);
        }
    }
    .nmz {
        width: 174px;
        height: 265px;
        top: 240px;
        left: 738px;
        &:hover,
        &.on {
            background-image: url(../images/nmz.png);
        }
    }
    .oz {
        width: 235px;
        height: 148px;
        top: 14px;
        left: 80px;
        &:hover,
        &.on {
            background-image: url(../images/oz.png);
        }
    }
    .yz {
        width: 380px;
        height: 303px;
        top: 26px;
        left: 154px;
        &:hover,
        &.on {
            background-image: url(../images/yz.png);
        }
    }
}
.map-outside-list {
    font-size: 0;
    li {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;
        margin-bottom: 40px;
        &:nth-child(2n+1) {
            padding-right: 20px;
        }
        &:nth-child(2n) {
            padding-left: 20px;
        }
    }
    .wrapper {
        display: flex;
        padding: 30px;
        background-color: #fff;
    }
    .img {
        position: relative;
        width: 308px;
        height: 200px;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .info {
        flex: 1;
        margin-left: 25px;
        overflow: hidden;
    }
    .t {
        font-size: 20px;
        font-weight: bold;
        color: #333;
        margin-bottom: 10px;
        padding-top: 15px;
    }
    .p {
        border-top: 1px dashed #b6b6b6;
        padding-top: 10px;
        font-size: 16px;
        line-height: 24px;
        color: #999;
    }
    .country {
        position: relative;
        padding-left: 25px;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            font-family: 'iconfont';
            content: '\e6f6';
            line-height: 24px;
            font-size: 18px;
            color: $red;
        }
    }
    .address {
        position: relative;
        padding-left: 25px;
        bottom: auto;
        float: none;
        text-align: left;
        width: auto;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            font-family: 'iconfont';
            content: '\e6f7';
            line-height: 24px;
            font-size: 18px;
            color: $red;
        }
    }
}