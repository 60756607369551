.job-list {
	.job-text {
		font-size: 14px;
		color: #777;
		line-height: 34px;
	}
	.job-img {
		padding-top: 30px;
		position: relative;
		font-size: 0px;
		.img-box {
			position: relative;
			display: inline-block;
			vertical-align: top;
			.img {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				overflow: hidden;
				img {
					transition: all .3s ease;
					width: 100%;
					height: 100%;
				}
			}
			.text {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				color: #fff;
				font-size: 24px;
				.icon,span {
					display: inline-block;
					vertical-align: middle;
				}
				.icon {
					display: none;
				}
			}
			&:nth-child(1) {
				width: 600px;
				height: 310px;
			}
			&:nth-child(2) {
				width: 586px;
				height: 310px;
				margin-left: 14px;
			}
			&:hover {
				.img {
					img {
						transform: scale(1.03);
					}
				}
			}
			&.on {
				.text {
					.icon {
						display: inline-block;
					}
				}
			}
		}
	}
	.job-form {
		margin: 30px 0px;
		.form-box {
			margin-right: 10px;
			float: left;
			width: 260px;
			height: 38px;
			box-sizing: border-box;
			display: flex;
			border: 1px solid #c0c0c0;
			color: #666;
			font-size: 14px;
			.text {
				padding-left: 14px;
				line-height: 36px;
			}
			.btn {
				cursor: pointer;
				font-size: 22px;
				line-height: 38px;
				width: 38px;
				text-align: center;
				color: #80848f;
				background: none;
				border: 0px;
				&:hover {
					opacity: .8;
				}
			}
			.input-box {
				z-index: 4;
				position: relative;
				padding-left: 14px;
				box-sizing: border-box;
				flex: 1;
				select {
					cursor: pointer;
				}
				select,input {
					width: 100%;
					height: 100%;
					background: none;
					border: 0px;
					color: #666;
					font-size: 14px;
					position: relative;
				}
			}
			.input-text-box {
				padding-left: 38px;
			}
			.select-box {
				&:after {
					z-index: -1;
					position: absolute;
					right: 14px;
					top: 50%;
					transform: translate(0,-50%);
					content: "";
					width: 10px;
					height: 6px;
					background: url(../images/job_icon_3.png);
					background-size: 100% 100%;
				}
			}
		}
	}
	.talent-recruitment {
		padding-bottom: 60px;
	    position: relative;
	    z-index: 22;
	    .talent-recruitment-title {
	    	position: relative;
	        width: 100%;
	        background: #9a9a9a;
	        height: 53px;
	        line-height: 53px;
	        font-size: 0;
	        margin-bottom: 18px;
	        font-size: 0px;
	        li {
	        	box-sizing: border-box;
	            display: inline-block;
	            vertical-align: middle;
	            text-align: center;
	            font-size: 16px;
	            color: #FAFAFA;
	            width: 146px;
	        }
	        .icon {
	        	position: absolute;
	        	right: 0px;
	        	top: 0;
	        }
	        .wp100 {
            	width: 100px;
            }
	        .name {
	            width: 220px;
	        }
	    }
	    .talent-recruitment-list {
	        width: 100%;
	        box-sizing: border-box;
	        .active {
	            .talent-title {
	                .icon-rotate {
	                    span {
	                        transform: rotate(0deg);
	                    }
	                }
	            }
	        }
	        > li:first-child {
	            margin-top: 0;
	        }
	        > li {
	            border: 1px solid #D7D7D7;
	            background: #FFF;
	            margin-top: 16px;
	            font-size: 0px;
	            transition: all .3s ease;
	            &:hover {
	            	box-shadow: 0 2px 18px rgba(0,0,0,.14);
	            	transform: translate(0,-5px);
	            }
	            a {
	            	display: block;
	            }
	            .talent-title {
	                position: relative;
	                width: 100%;
	                height: 53px;
	                line-height: 16px;
	                font-size: 0;
	                cursor: pointer;
	                li {
	                    position: relative;
	                    top: 50%;
	                    transform: translate(0,-50%);
	                    display: inline-block;
	                    vertical-align: top;
	                    text-align: center;
	                    font-size: 14px;
	                    max-height: 16*3px;
	                    color: #333;
	                    overflow: hidden;
	                    width: 146px;
	                    padding: 0 6px;
	                    box-sizing: border-box;
	                }
	                .wp100 {
	                	width: 100px;
	                }
	                .name {
	                    width: 220px;
	                }
	                .icon {
			        	position: absolute;
			        	right: 14px;
			        	top: 50%;
			        	transform: translate(0,-50%);
			        	width: auto;
			        	.iconfont {
			        		transition: all .3s ease;
			        		width: 34px;
			        		height: 34px;
			        		line-height: 34px;
			        		text-align: center;
			        		border-radius: 50%;
			        		font-size: 20px;
			        		border: 1px solid #e6e6e6;
			        	}
			        }
	            }
	        }
	    }
	}
}
.job-info {
	position: relative;
	max-width: 1440px;
	padding: 0 20px;
	min-width: 1300px;
	box-sizing: border-box;
	margin: 0 auto;
	&-box {
		min-height: 700px;
		padding-bottom: 70px;
		box-sizing: border-box;
		width: 100%;
		background: #fff url(../images/jog_bg.jpg) no-repeat center center;
		background-size: cover;
		.title {
			padding-top: 44px;
			font-size: 28px;
			font-weight: bold;
			text-align: center;
			color: #333;
		}
		.title2 {
			margin-top: 10px;
			color: #999;
			font-size: 14px;
			text-align: center;
			padding-bottom: 20px;
			border-bottom: 1px solid #e8e8e8;
		}
		.job {
			padding: 0 18px;
			padding-top: 40px;
			font-size: 24px;
			color: #333;
			font-weight: bold;
			line-height: 30px;
		}
		.article {
			margin-top: 30px;
			padding: 0 18px;
			color: #666;
			font-size: 14px;
			line-height: 30px;
		}
		.button {
			margin-top: 12px;
			margin-left: 18px;
			border-radius: 16px;
			padding: 0 12px;
			border: 1px solid #ccc;
			font-size: 14px;
			color: #000;
			line-height: 30px;
			display: inline-block;
			transition: all .3s ease;
			&:hover {
				border-color: $red;
				background: $red;
				color: #fff;
			}
		}
	}
}

.school {
	&-one {
		padding-top: 60px;
		padding-bottom: 112px;
		background: #fff url(../images/bg6.jpg) no-repeat center center;
		background-size: cover;
		&-box {
			width: 1270px;
			margin: 0 auto;
			padding-left: 200px;
			font-size: 0px;
			@media screen and (max-width: 1500px) {
				padding-left: 0px;
			}
		}
		&-img {
			display: inline-block;
			vertical-align: middle;
			width: 50%;
			text-align: center;
			img {
				max-width: 100%;
				height: auto !important;
			}
		}
		&-text {
			display: inline-block;
			vertical-align: middle;
			width: 50%;
			padding-right: 50px;
			box-sizing: border-box;
			.title {
				font-size: 24px;
				font-weight: bold;
				color: #333;
				padding-bottom: 24px;
				margin-bottom: 24px;
				position: relative;
				&:after {
					position: absolute;
					content: "";
					left: 0px;
					bottom: 0px;
					width: 40px;
					height: 2px;
					background: $red;
				}
			}
			.article {
				font-size: 14px;
				color: #666;
				line-height: 28px;
			}
		}
	}
	&-two {
		padding-top: 120px;
		padding-bottom: 160px;
		background: #fff url(../images/bg7.jpg) no-repeat center center;
		background-size: cover;
		&-box {
			padding-left: 200px;
			position: relative;
			width: 1270px;
			margin: 0 auto;
			color: #666;
			font-size: 14px;
			line-height: 30px;
			img {
				max-width: 100%;
				height: auto !important;
			}
			@media screen and (max-width: 1500px) {
				padding-left: 0px;
				img {
					display: block;
					margin: 0 auto;
				}
			}
			.title {
				position: absolute;
				top: 0px;
				font-size: 24px;
				font-weight: bold;
				color: #333;
				padding-bottom: 24px;
				margin-bottom: 24px;
				position: relative;
				&:after {
					position: absolute;
					content: "";
					left: 0px;
					bottom: 0px;
					width: 40px;
					height: 2px;
					background: $red;
				}
			}
		}
	}
	&-three {
		padding-top: 156px;
		padding-bottom: 120px;
		background: #fff url(../images/bg8.jpg) no-repeat center center;
		background-size: cover;
		&-box {
			width: 1270px;
			margin: 0 auto;
			padding-left: 200px;
			font-size: 0px;
			@media screen and (max-width: 1500px) {
				padding-left: 0px;
			}
			.img {
				float: left;
				@media screen and (max-width: 1500px) {
					padding-right: 30px;
				}
			}
			.text {
				position: relative;
				overflow: hidden;
			}
			.title {
				margin-left: 20px;
				font-size: 24px;
				font-weight: bold;
				color: #333;
				padding-bottom: 24px;
				margin-bottom: 24px;
				position: relative;
				&:after {
					position: absolute;
					content: "";
					left: 0px;
					bottom: 0px;
					width: 40px;
					height: 2px;
					background: $red;
				}
			}
			.article {
				width: 100%;
				font-size: 0px;
			}
			.text-article {
				padding-right: 15px;
				padding-left: 20px;
				box-sizing: border-box;
				width: 42%;
				display: inline-block;
				vertical-align: top;
				font-size: 14px;
				line-height: 30px;
				color: #777;
				span {
					font-weight: bold;
					color: #444;
				}
				+ .text-article {
					border-left: 1px dashed #888;
				}
			}
		}
	}
	&-four {
		padding-top: 120px;
		padding-bottom: 110px;
		background: #fff url(../images/bg9.jpg) no-repeat center center;
		background-size: cover;
		.title {
			margin-left: 25px;
			font-size: 24px;
			font-weight: bold;
			color: #333;
			padding-bottom: 24px;
			margin-bottom: 24px;
			position: relative;
			&:after {
				position: absolute;
				content: "";
				left: 0px;
				bottom: 0px;
				width: 40px;
				height: 2px;
				background: $red;
			}
		}
		&-box {
			width: 1270px;
			margin: 0 auto;
			padding-left: 200px;
			font-size: 0px;
			@media screen and (max-width: 1500px) {
				padding-left: 0px;
				width: 1200px;
			}
			.article {
				width: 100%;
				overflow: hidden;
				.li {
					margin-right: 100px;
					display: inline-block;
					vertical-align: top;
					border-bottom: 1px dashed #a7a7a7;
					padding-left: 25px;
					box-sizing: border-box;
					width: 500px;
					.li-title {
						padding-top: 25px;
						font-size: 18px;
						color: #333;
						margin-bottom: 13px;
					}
					.li-p {
						font-size: 14px;
						color: #777;
						line-height: 24px;
						//height: 48px;
						overflow: hidden;
					}
				}
			}
		}
	}
}