$red: #f00;
.dn {
    display: none;
}
body {
    min-width: 1300px;
}
.pad20 {
    padding: 0 20px;
}
.gray {
    background: #f0f0f0 none no-repeat center center;
    background-size: cover;
}
.wp884 {
    max-width: 884px;
    margin: 0 auto;
}
.wp1048 {
    max-width: 1048px;
    margin: 0 auto;
}
.wp1200 {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}
.wp1270 {
    max-width: 1270px;
    margin: 0 auto;
    position: relative;
}
.wp1300 {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
}
.wp1330 {
    max-width: 1330px;
    min-width: 1300px;
    margin: 0 auto;
}
.wp1350 {
    max-width: 1350px;
    min-width: 1300px;
    position: relative;
    margin: 0 auto;
}
.wp1400 {
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
}
.wp1420 {
    width: 1420px;
    max-width: 100%;
    margin: 0 auto;
}
.wp1440 {
    width: 1440px;
    margin: 0 auto;
    @media screen and (max-width: 1600px) {
        width: 1300px;
    }
}
.wp1464 {
    width: 1464px;
    margin: 0 auto;
    @media screen and (max-width: 1500px) {
        width: 1300px;
    }
}
.sub-iframe {
    min-height: 700px;
    padding-top: 40px;
    padding-bottom: 70px;
}
.sub-banner {
    position: relative;
    width: 100%;
    //padding-bottom: (540/1920)*100%;
    min-width: 1300px;
    background: #fff none no-repeat center center;
    background-size: cover;
    .bg {
        img {
            display: block;
            width: 100%;
        }
    }
}
.padding0 {
    padding: 0 !important;
}
.address {
    width: 100%;
    position: absolute;
    bottom: 40px;
    text-align: right;
    font-size: 13px;
    .wp1350 {
        padding: 0 20px;
        box-sizing: border-box;
    }
    a {
        display: inline;
        padding: 0 4px;
        &:hover {
            opacity: .7;
        }
    }
    h1 {
        display: inline;
        font-size: 13px;
    }
    .f8f8f8 {
        color: #888;
        a {
            color: #888;
        }
    }
    .fff {
        color: #fff;
        a {
            color: #fff;
        }
    }
    .color-777 {
        color: #777;
        a {
            color: #777;
        }
    }
}
.sub-nav {
    height: 90px;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    font-size: 0px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    a {
        position: relative;
        width: 225px;
        height: 90px;
        display: inline-block;
        vertical-align: top;
        transition: all .3s ease;
        @media screen and (max-height: 1560px) {
            width: 200px;
        }
        @media screen and (max-height: 1400px) {
            width: 180px;
        }
        + a:after {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translate(0, -50%);
            content: '';
            width: 1px;
            height: 22px;
            background: #e5e5e5;
        }
        &.on {
            &:after {
                opacity: 0;
            }
        }
        &.on, &:hover {
            background: #f00;
            .title {
                color: #fff;
            }
            .icon {
                .dn {
                    display: inline-block;
                }
                .dk {
                    display: none;
                }
            }
        }
        .text {
            line-height: 90px;
            display: inline-block;
            vertical-align: top;
        }
        .icon {
            display: inline-block;
            vertical-align: middle;
            transition: all .3s ease;
            .dn {
                display: none;
            }
            .dk {
                display: inline-block;
            }
        }
        .title {
            padding-left: 10px;
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            color: #666;
            transition: all .3s ease;
        }
    }
}
.sub-nav2 {
    padding-bottom: 44px;
    text-align: center;
    font-size: 0px;
    a {
        display: inline-block;
        vertical-align: middle;
        border-radius: 6px;
        width: 176px;
        height: 70px;
        border: 1px solid #ddd;
        box-sizing: border-box;
        background: #fff;
        font-size: 14px;
        text-align: center;
        margin: 0 5px;
        transition: all .3s ease;
        .text {
            line-height: 70px;
            display: inline-block;
            vertical-align: top;
        }
        .icon {
            display: inline-block;
            vertical-align: middle;
            transition: all .3s ease;
            .dn {
                display: none;
            }
            .dk {
                display: inline-block;
            }
        }
        .title {
            padding-left: 6px;
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            color: #666;
            transition: all .3s ease;
        }
        &.on, &:hover {
            background: #e1e1e1;
        }
    }
}
.year-components {
    position: relative;
    height: 80px;
    width: 1048px;
    margin: 0 auto 40px;
    padding: 0 40px;
    box-sizing: border-box;
    .next {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 46px;
        transform: rotate(180deg);
        width: 14px;
        height: 25px;
        background: url(../images/year_icon_1.png) no-repeat;
    }
    .prev {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 46px;
        width: 14px;
        height: 25px;
        background: url(../images/year_icon_1.png) no-repeat;
    }
    .year-swiper {
        position: relative;
        height: 100%;
        width: 100%;
        &-box {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            .swiper-slide {
                cursor: pointer;
                position: relative;
                width: (1/8)*100%;
                height: 100%;
                display: block;
                &.on {
                    .year-box {
                        .text {
                            top: 0px;
                            color: $red;
                            font-size: 30px;
                        }
                        .radius {
                            position: relative;
                            width: 36px;
                            height: 36px;
                            border: 1px solid $red;
                            margin-left: -20px;
                            top: 42px;
                            &:after {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                margin-left: -6px;
                                margin-top: -6px;
                                width: 12px;
                                height: 12px;
                                border-radius: 50%;
                                background: $red;
                            }
                        }
                    }
                }
                .year {
                    position: relative;
                    margin: 0 auto;
                    width: 46px;
                    height: 100%;
                    background: #f0f0f0;
                    &-box {
                        .text {
                            position: absolute;
                            top: 10px;
                            left: 50%;
                            white-space: nowrap;
                            transform: translate(-50%, 0);
                            font-size: 20px;
                            color: #333;
                        }
                        .radius {
                            position: absolute;
                            left: 50%;
                            margin-left: -10px;
                            top: 50px;
                            width: 20px;
                            height: 20px;
                            border: 1px solid #bbb;
                            box-sizing: border-box;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
        .year-bg {
            position: absolute;
            height: 14px;
            top: 52px;
            left: 0px;
            width: 100%;
            padding: 0 40px;
            box-sizing: border-box;
            &-box {
                width: 100%;
                height: 100%;
                background: url(../images/bg.png) left top;
            }
        }
    }
}
.page {
    text-align: center;
    font-size: 0px;
    span, a {
        margin: 0 7px;
        display: inline-block;
        padding: 0 19px;
        line-height: 46px;
        background: #fff;
        border-radius: 23px;
        font-size: 14px;
        border: 1px solid #dbdbdb;
        transition: all .3s ease;
        &.on, &:hover {
            border-color: $red;
            background: $red;
            color: #fff;
        }
    }
}
.prompt {
    max-width: 1370px;
    min-width: 1200px;
    height: 660px;
    border-radius: 20px;
    margin: 0 auto;
    background: #fff;
    text-align: center;
    font-size: 22px;
    color: #556;
    .img {
        padding-top: 140px;
        padding-bottom: 20px;
    }
    .btn {
        margin-top: 46px;
        a {
            margin: 0 4px;
            display: inline-block;
            width: 174px;
            height: 40px;
            background: #ebeced;
            border-radius: 20px;
            &:hover {
                opacity: .8;
            }
            i {
                padding-right: 10px;
                margin-bottom: -3px;
            }
            i, span {
                line-height: 40px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .product-address {
        text-align: left;
        color: #555;
        font-size: 14px;
        line-height: 36px;
        padding-top: 40px;
        padding-left: 40px;
        a {
            color: #555;
            &:hover {
                opacity: .8;
            }
        }
        h1 {
            display: inline;
            font-size: 14px;
            line-height: 36px;
            color: #555;
        }
    }
}
.bread {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    &.mb-0 {
        margin-bottom: 0;
    }
    a {
        color: #333;
        &:hover {
            color: $red;
        }
    }
    h1 {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        line-height: 20px;
    }
    h2 {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        line-height: 20px;
        color: #333;
        &:hover {
            color: $red;
        }
    }
}