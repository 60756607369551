.case-classify-con {
    background: #fff;
    padding: 30px 30px 10px 30px;
    margin-bottom: 45px;
    .item {
        display: flex;
        margin-bottom: 15px;
    }
    .word {
        font-size: 15px;
        color: #777;
        width: 65px;
        line-height: 26px;
    }
    .link {
        flex: 1;
        overflow: hidden;
        a {
            display: inline-block;
            vertical-align: top;
            height: 24px;
            line-height: 24px;
            text-align: center;
            margin-right: 20px;
            margin-bottom: 5px;
            padding: 0 14px;
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            background: #f9f9f9;
            font-size: 13px;
            color: #272727;
            transition: all .2s;
            &.on,
            &:hover {
                background: $red;
                border-color: $red;
                color: #fff;
            }
        }
    }
}
.case-list {
    font-size: 0;
    padding-bottom: 30px;
    li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        box-sizing: border-box;
        margin-bottom: 30px;
        &:nth-child(3n+1) {
            padding-right: 20px;
        }
        &:nth-child(3n+2) {
            padding: 0 10px;
        }
        &:nth-child(3n+3) {
            padding-left: 20px;
        }
    }
    .wrapper {
        background: #fff;
    }
    .img {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-top: percentage(265/430);
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            transition: all .5s;
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
    .info {
        padding: 20px 30px 30px;
    }
    .title {
        height: 54px;
        line-height: 54px;
        overflow: hidden;
        font-size: 22px;
        font-weight: bold;
        color: #333;
        border-bottom: 1px dashed #cecece;
        text-overflow: ellipsis;
        white-space: nowrap;
        a {
            color: #333;
            &:hover {
                color: $red;
            }
        }
    }
    .p {
        font-size: 15px;
        line-height: 30px;
        color: #888;
        padding-top: 10px;
        height: 90px;
        overflow: hidden;
        a {
            color: #888;
            &:hover {
                color: $red;
            }
        }
    }
    .li {
        display: flex;
        max-height: 60px;
        overflow: hidden;
        i {
            width: 30px;
            color: $red;
            font-size: 20px;
            line-height: 30px;
        }
        p {
            flex: 1;
            overflow: hidden;
        }
    }
}
.case-detail-top {
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 60px 70px;
    display: flex;
    overflow: hidden;
    margin-bottom: 20px;
    margin-top: 40px;
    .info {
        flex: 1;
        overflow: hidden;
        margin-right: 60px;
    }
    .img {
        max-width: 647px;
        width: 100%;
        img {
            display: block;
            max-width: 100%;
        }
    }
    .top {
        padding-bottom: 17px;
        border-bottom: 1px dashed #8b8b8b;
        img {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
        }
        .title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 28px;
            font-weight: bold;
            color: #333;
            line-height: 47px;
            display: inline-block;
            vertical-align: top;
        }
    }
    .p {
        padding-top: 20px;
        line-height: 30px;
        color: #777;
        @include articleReset(13px, 30px);
    }
    .tags {
        font-size: 13px;
        line-height: 30px;
        color: #777;
        padding-top: 20px;
        a {
            color: #777;
            &:hover {
                color: $red;
            }
        }
    }
}
.case-detail-article {
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 30px;
    padding: 40px 0;
    .top {
        width: 1045px;
        margin: 0 auto 20px;
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before,
        &::after {
            // position: absolute;
            // width: 100%;
            flex: 1;
            height: 1px;
            background: #cfcfd1;
            top: 50%;
            left: 0;
            content: '';
            z-index: 1;
            display: block;
        }
        span {
            display: inline-block;
            vertical-align: top;
            padding: 0 20px;
            font-size: 26px;
            color: #000;
            position: relative;
            z-index: 2;
        }
    }
    .article {
        font-size: 14px;
        line-height: 24px;
        color: rgba(#5e5e5e, .8);
        max-width: 1190px;
        margin: 0 auto;
        p {
            margin-bottom: 20px;
        }
        img {
            max-width: 100%;
            height: auto !important;
        }
    }
}

.case-detail-more {
    margin-bottom: 30px;
    padding: 40px 0;
    .top {
        width: 1045px;
        margin: 0 auto 20px;
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before,
        &::after {
            // position: absolute;
            // width: 100%;
            flex: 1;
            height: 1px;
            background: #cfcfd1;
            top: 50%;
            left: 0;
            content: '';
            z-index: 1;
            display: block;
        }
        span {
            display: inline-block;
            vertical-align: top;
            padding: 0 20px;
            font-size: 26px;
            color: #000;
            position: relative;
            z-index: 2;
        }
    }
    .case-detail-prev,
    .case-detail-next {
        position: absolute;
        top: 50%;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        background: #555;
        cursor: pointer;
        font-size: 16px;
        transition: background-color .2s;
        outline: none;
        border-radius: 100%;
        margin-top: -15px;
        z-index: 10;
        &:hover {
            background-color: $red;
        }
    }
    .case-detail-prev {
        left: -40px;
        transform: rotate(180deg);
    }
    .case-detail-next {
        right: -40px;
    }
    @media (max-width: 1440px) {
        .case-detail-prev {
            left: -35px;
        }
        .case-detail-next {
            right: -35px;
        }
    }
    @media (max-width: 1366px) {
        .case-detail-prev {
            left: -5px;
        }
        .case-detail-next {
            right: -5px;
        }
    }
}
.case-detail-swiper {
    position: relative;
    .swiper-slide {
        background-color: #fff;
        .img {
            position: relative;
            display: block;
            width: 100%;
            height: 0;
            padding-top: percentage(265/430);
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
                transition: all .5s;
            }
            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }
        .info {
            padding: 20px 30px 30px;
        }
        .title {
            height: 54px;
            line-height: 54px;
            overflow: hidden;
            font-size: 22px;
            font-weight: bold;
            color: #333;
            border-bottom: 1px dashed #cecece;
            text-overflow: ellipsis;
            white-space: nowrap;
            a {
                color: #333;
                &:hover {
                    color: $red;
                }
            }
        }
        .p {
            font-size: 15px;
            line-height: 30px;
            color: #888;
            padding-top: 10px;
            height: 90px;
            overflow: hidden;
            a {
                color: #888;
                &:hover {
                    color: $red;
                }
            }
        }
        .li {
            display: flex;
            max-height: 60px;
            overflow: hidden;
            i {
                width: 30px;
                color: $red;
                font-size: 20px;
                line-height: 30px;
            }
            p {
                flex: 1;
                overflow: hidden;
            }
        }
    }
}
.case-detail-form {
    padding: 40px 0;
    .top {
        width: 1045px;
        margin: 0 auto 20px;
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before,
        &::after {
            // position: absolute;
            // width: 100%;
            flex: 1;
            height: 1px;
            background: #cfcfd1;
            top: 50%;
            left: 0;
            content: '';
            z-index: 1;
            display: block;
        }
        span {
            display: inline-block;
            vertical-align: top;
            padding: 0 20px;
            font-size: 26px;
            color: #000;
            position: relative;
            z-index: 2;
        }
    }
    form {
        font-size: 0px;
        .input-box {
            margin-top: 30px;
            width: 33.33%;
            display: inline-block;
            vertical-align: top;
            .input {
                box-shadow: 1px 1px 4px rgba(0, 0, 0, .04);
                position: relative;
                width: 100%;
                height: 50px;
                background: #fff;
                padding-left: 110px;
                box-sizing: border-box;
                .text {
                    position: absolute;
                    line-height: 50px;
                    left: 20px;
                    top: 0px;
                    font-size: 14px;
                    color: #666;
                }
                input {
                    font-size: 14px;
                    width: 100%;
                    height: 100%;
                    border: 0px;
                }
            }
            + .input-box {
                padding-left: 15px;
                box-sizing: border-box;
            }
        }
        .select-box {
            margin-top: 30px;
            width: 50%;
            display: inline-block;
            vertical-align: top;
            .select {
                box-shadow: 1px 1px 4px rgba(0, 0, 0, .04);
                position: relative;
                width: 100%;
                height: 50px;
                background: #fff;
                padding-left: 150px;
                box-sizing: border-box;
                .text {
                    position: absolute;
                    line-height: 50px;
                    left: 20px;
                    top: 0px;
                    font-size: 14px;
                    color: #666;
                }
                select {
                    font-size: 14px;
                    width: 100%;
                    height: 100%;
                    border: 0px;
                }
            }
            + .select-box {
                padding-left: 15px;
                box-sizing: border-box;
                .select {
                    padding-left: 190px;
                }
            }
        }
        .textarea-box {
            position: relative;
            margin-top: 14px;
            background: #fff;
            width: 100%;
            height: 170px;
            padding: 10px 20px 10px 110px;
            box-sizing: border-box;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, .04);
            .text {
                position: absolute;
                top: 0px;
                left: 0px;
                margin-left: 20px;
                font-size: 14px;
                color: #666;
                line-height: 50px;
            }
            textarea {
                font-size: 14px;
                line-height: 30px;
                width: 100%;
                height: 100%;
                border: 0px;
                resize: none;
            }
        }
        button {
            margin: 22px auto 0;
            width: 224px;
            height: 54px;
            background: $red;
            border: 0;
            cursor: pointer;
            color: #fff;
            font-size: 14px;
            appearance: none;
            border: none;
            display: block;
            &:hover {
                opacity: .8;
            }
        }
        .ver-box {
            font-size: 0;
            margin-top: 15px;
            .box {
                display: inline-block;
                vertical-align: top;
                width: 200px;
                background: #fff;
                height: 50px;
                box-shadow: 1px 1px 4px rgba(0, 0, 0, .04);
                line-height: 50px;
            }
            .text {
                font-size: 14px;
                color: #666;
                padding-left: 20px;
                display: inline-block;
                vertical-align: top;
                margin-right: 5px;
            }
            input {
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                color: #666;
                width: 120px;
                height: 50px;
                background: none;
                border: none;
                appearance: none;
            }
        }
        .ver-img {
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            height: 50px;
            img {
                display: block;
                height: 50px;
                width: auto;
            }
        }
    }
}
.base-detail-top {
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 60px 75px;
    //display: flex;
    overflow: hidden;
    margin-bottom: 20px;
    margin-top: 40px;
    .flex-box {
        display: flex;
    }
    .info {
        flex: 1;
        overflow: hidden;
        margin-right: 60px;
        padding-bottom: 10px;
    }
    .img {
        max-width: 647px;
        width: 100%;
        img {
            display: block;
            max-width: 100%;
        }
    }
    .top {
        padding-bottom: 30px;
        border-bottom: 1px dashed #ddd;
        img {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
        }
        .title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 28px;
            color: #000;
            line-height: 47px;
            display: inline-block;
            vertical-align: top;
        }
    }
    .p {
        padding-top: 14px;
        line-height: 30px;
        color: #888;
        @include articleReset(14px, 30px);
    }
    .address {
        font-size: 14px;
        line-height: 30px;
        color: #888;
        position: static;
        text-align: left;
        margin-top: 40px;
        a {
            color: $red;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.base-detail-right {
    width: 380px;
    margin-left: 70px;
    display: flex;
    flex-flow: column;
}
.base-detail-address,
.base-detail-cat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
    flex: 1;
    .title {
        font-size: 22px;
        color: #333;
        line-height: 44px;
    }
    .p {
        font-size: 16px;
        line-height: 25px;
        color: #346ef1;
        margin-top: 20px;
    }
}
.base-detail-address {
    background-image: url(../images/base_bg1.png);
    border-top-right-radius: 15px;
}
.base-detail-cat {
    background-image: url(../images/base_bg2.png);
    border-top: 1px dashed #d0d0d0;
}
.base-detail-img {
    position: relative;
    overflow: hidden;
    img {
        display: block;
        width: 100%;
    }
    .t {
        position: absolute;
        top: 0;
        right: 0;
        background: $red;
        color: #fff;
        z-index: 2;
        font-size: 24px;
        line-height: 80px;
        padding: 0 98px;
        border-bottom-left-radius: 15px;
    }
}
.base-detail-swiper {
    position: relative;
    //.swiper-slide {
    //    background-color: #fff;
    //}
    .wrapper {
        padding: 10px;
        display: block;
        background-color: #fff;
        &:hover {
            .img img {
                transform: scale(1.1);
            }
            .t {
                color: $red;
            }
        }
    }
    .img {
        width: 100%;
        padding-top: percentage(248/398);
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
            transition: all .6s;
        }
    }
    .info {
        padding: 15px 24px;
    }
    .t {
        font-size: 18px;
        color: #000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 15px;
        transition: all .2s;
    }
    .address {
        position: relative;
        padding-left: 20px;
        font-size: 14px;
        color: #6a6a6a;
        line-height: 22px;
        text-align: left;
        bottom: auto;
        margin-bottom: 8px;
        height: 22px;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            line-height: 22px;
            font-family: 'iconfont';
            content: '\e69a';
            color: $red;
        }
        &:empty {
            &:before {
                display: none;
            }
        }
    }
    .phone {
        position: relative;
        padding-left: 20px;
        font-size: 14px;
        color: #6a6a6a;
        line-height: 22px;
        //font-style: italic;
        text-align: left;
        height: 22px;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            line-height: 22px;
            font-family: 'iconfont';
            content: '\e6f5';
            color: $red;
            font-style: normal;
        }
        &:empty {
            &:before {
                display: none;
            }
        }
    }
}