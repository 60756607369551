.index-banner {
    position: relative;
    width: 100%;
    min-width: 1300px;
    padding-bottom: (890/1920)*100%;
    &-box {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        a {
            position: relative;
            display: block;
            img {
                position: relative;
                width: 100%;
                height: 100%;
            }
        }
    }
    &-bg {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 3;
        img {
            display: block;
            width: 100%;
        }
    }
    .index-pagination {
        position: absolute;
        top: auto;
        bottom: 100px;
        left: 0;
        text-align: center;
        width: 100%;
        z-index: 10;
        span {
            opacity: 1;
            background: #fff;
            width: 10px;
            height: 10px;
            &.swiper-pagination-bullet-active {
                background: $red;
            }
        }
    }
}
.index-title {
    text-align: center;
    color: #444;
    .t,
    h1 {
        display: inline-block;
        font-size: 60px;
        position: relative;
        padding-bottom: 15px;
        &:after {
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 90px;
            height: 3px;
            content: '';
            margin-left: -45px;
            background: #f00;
        }
        //padding-left: 66px;
        //line-height: 56px;
        //height: 85px;
        //background: url(../images/index_icon_1.png) no-repeat left top;
    }
    .text {
        width: 736px;
        display: block;
        margin: 0 auto;
        color: #999;
        font-size: 14px;
        line-height: 26px;
    }
}
//.index-btn {
//    position: relative;
//    z-index: 10;
//    min-width: 1300px;
//    padding: 0 20px;
//    box-sizing: border-box;
//    &-box {
//        margin: 0 auto;
//        background: #fff;
//        margin-top: -74px;
//        height: 148px;
//        max-width: 1713px;
//        font-size: 0px;
//        box-shadow: 0 7px 18px rgba(0, 0, 0, .12);
//        .btn {
//            width: 33.33%;
//            height: 100%;
//            display: inline-block;
//            vertical-align: middle;
//            box-sizing: border-box;
//            text-align: center;
//            .icon1:before {
//                content: "\e722";
//            }
//            .icon2:before {
//                content: "\e63c";
//            }
//            .icon3:before {
//                content: "\e784";
//            }
//            &:hover {
//                .iconfont {
//                    color: $red;
//                }
//                .text {
//                    h5 {
//                        color: $red;
//                        font-weight: bold;
//                    }
//                }
//            }
//            .iconfont {
//                margin-right: 40px;
//                margin-left: 36px;
//                line-height: 148px;
//                font-size: 56px;
//                color: #333;
//                display: inline-block;
//                vertical-align: middle;
//                transition: all .3s ease;
//            }
//            .text {
//                display: inline-block;
//                vertical-align: middle;
//                width: 240px;
//                text-align: left;
//                h2 {
//                    font-size: 16px;
//                    margin-bottom: 4px;
//                    color: #000;
//                    transition: all .3s ease;
//                }
//                .p {
//                    color: #999;
//                    line-height: 20px;
//                    font-size: 12px;
//                }
//            }
//            + .btn {
//                border-left: 1px solid #e7e7e7;
//            }
//        }
//    }
//}
.index-vocational-work {
    min-width: 1300px;
    padding: 70px 20px 0;
    box-sizing: border-box;
    position: relative;
    z-index: 3;
    &-top {
        margin: 55px auto 0;
        max-width: 1718px;
        height: 816px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        overflow: hidden;
        .word {
            position: absolute;
            top: 100px;
            left: 120px;
            color: #fff;
            width: 565px;
            .t {
                font-size: 42px;
                position: relative;
                padding-bottom: 22px;
                margin-bottom: 15px;
                &:before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 2px;
                    width: 46px;
                    content: '';
                    background: #fff;
                }
            }
            .p {
                font-size: 15px;
                line-height: 30px;
            }
        }
    }
}
.index-vocational-swiper {
    position: absolute;
    z-index: 2;
    left: 0;
    width: 100%;
    bottom: 89px;
    padding-top: 64px;
    &-prev,
    &-next {
        position: absolute;
        top: 0;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border: 2px solid #fff;
        transition: all .2s;
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        &:hover {
            background: #f00;
            border-color: #f00;
        }
    }
    &-prev {
        right: 83px;
        transform: rotate(180deg);
    }
    &-next {
        right: 31px;
    }
    .swiper-slide {
        display: inline-block;
        vertical-align: top;
        width: auto;
        .img {
            width: 453px;
            height: 251px;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .5s;
            }
        }
        .t {
            text-align: center;
            height: 68px;
            line-height: 68px;
            overflow: hidden;
            font-size: 20px;
            font-weight: bold;
            color: #333;
            background-color: #fff;
        }
        &:hover {
            .img img {
                transform: scale(1.05);
            }
            .t {
                color: #f00;
            }
        }
    }
}
.index-vocational-list {
    display: flex;
    max-width: 1718px;
    margin: 0 auto;
    .item {
        flex: 1;
        display: block;
        margin-top: 30px;
        position: relative;
        + .item {
            margin-left: 39px;
        }
        &:hover {
            .bg img {
                transform: scale(1.05, 1.05);
            }
        }
    }
    .bg {
        position: relative;
        width: 100%;
        height: 669px;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .5s;
        }
    }
    .title {
        position: absolute;
        text-align: center;
        width: 100%;
        left: 0;
        top: 80px;
        z-index: 5;
        padding-bottom: 18px;
        color: #fff;
        font-size: 34px;
        font-weight: bold;
        &:after {
            position: absolute;
            left: 50%;
            bottom: 0;
            background-color: #fff;
            content: '';
            width: 24px;
            height: 3px;
            margin-left: -12px;
        }
    }
}
.index-investor {
    position: relative;
    margin-top: -136px;
    padding: 270px 20px 180px;
    background-image: url(../images/index_img_10.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    &-list {
        margin-top: 75px;
        font-size: 0;
        text-align: center;
        li {
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;
            width: 25%;
            max-width: 430px;
            position: relative;
            padding: 0 20px;
            + li {
                &:before {
                    position: absolute;
                    left: 0;
                    top: 25px;
                    width: 1px;
                    content: '';
                    height: 38px;
                    background: #d0d0d0;
                }
            }
        }
        .num-box {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .num {
            font-weight: bold;
            font-family: Arial;
            font-size: 56px;
            color: #f00;
        }
        .unit {
            font-size: 10px;
            color: #333;
            p {
                font-size: 14px;
            }
        }
        .p {
            font-size: 16px;
            color: #333;
            line-height: 24px;
        }
    }
}
.index-report {
    position: relative;
    overflow: hidden;
    .bg {
        width: 100%;
        img {
            display: block;
            width: 100%;
        }
    }
    .word {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        backface-visibility: hidden;
        .t {
            color: #fff;
            font-size: 60px;
            font-weight: bold;
            margin-bottom: 25px;
        }
        .btn {
            display: inline-block;
            vertical-align: top;
            color: #fff;
            padding: 0 50px;
            height: 62px;
            line-height: 62px;
            border-radius: 31px;
            font-size: 24px;
            background: rgba(#01ae42, .6);
            transition: all .2s;
            &:hover {
                background: rgba(#01ae42, 1);
            }
        }
    }
}
.index-brand {
    background-image: url(../images/index_img_12.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    padding: 225px 20px 172px;
    position: relative;
    overflow: hidden;
    z-index: 2;
    margin-top: -153px;
    //box-sizing: border-box;
    //height: 950px;
    .box {
        display: flex;
        width: 1400px;
        max-width: 100%;
        margin: 0 auto;
        border-top: 2px solid #fff;
        padding-top: 122px;
    }
    .index-video-box {
        width: 718px;
        height: 406px;
        position: relative;
        overflow: hidden;
        video {
            display: block;
            width: 100%;
            height: 100%;
        }
        .video-js {
            width: 100%;
            height: 100%;
            background: none;
            .vjs-big-play-button {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .info {
        flex: 1;
        overflow: hidden;
        margin: 0 70px 0 47px;
        color: #fff;
    }
    .title {
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 20px;
        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            background: #fff;
            content: '';
            width: 140px;
            height: 3px;
        }
        .small {
            font-size: 42px;
        }
        .big {
            font-size: 68px;
        }
    }
    .p {
        font-size: 13px;
        line-height: 30px;
        text-align: justify;
        @include multiEllipsis(8);
    }
}
.index-news {
    padding: 100px 20px 130px;
    background: #e5e5e5;
    &-box {
        margin: 45px auto 0;
        width: 1480px;
        max-width: 100%;
        display: flex;
        .topic-box {
            width: 536px;
            height: 546px;
            display: block;
            background-color: #fff;
            &:hover {
                .img img {
                    transform: scale(1.05, 1.05);
                }
                .title {
                    color: #f00;
                }
            }
            .img {
                width: 536px;
                height: 370px;
                overflow: hidden;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all .5s;
                }
            }
            .info {
                box-sizing: border-box;
                padding: 20px 35px 25px;
                height: 170px;
            }
            .title {
                font-size: 18px;
                font-weight: bold;
                color: #444;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-bottom: 10px;
                height: 28px;
                transition: all .5s;
                line-height: 28px;
            }
            .p {
                font-size: 16px;
                line-height: 26px;
                height: 52px;
                overflow: hidden;
                color: #999;
                margin-bottom: 5px;
            }
            .tool {
                font-size: 14px;
                line-height: 26px;
                color: #ababab;
                height: 26px;
                overflow: hidden;
                span {
                    color: #f00;
                }
            }
        }
        .middle-box {
            margin-left: 15px;
            width: 312px;
        }
        .news-top-box {
            width: 100%;
            display: block;
            background-color: #fff;
            &:hover {
                .img img {
                    transform: scale(1.05, 1.05);
                }
                .title {
                    color: #f00;
                }
            }
            .img {
                width: 312px;
                height: 215px;
                overflow: hidden;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all .5s;
                }
            }
            .info {
                box-sizing: border-box;
                padding: 10px 20px;
                height: 88px;
            }
            .title {
                font-size: 18px;
                font-weight: bold;
                color: #444;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                height: 34px;
                transition: all .5s;
                line-height: 34px;
            }
            .tool {
                font-size: 14px;
                line-height: 26px;
                color: #ababab;
                height: 26px;
                overflow: hidden;
                span {
                    color: #f00;
                }
            }
        }
        .news-bottom-box {
            display: block;
            position: relative;
            width: 312px;
            height: 215px;
            overflow: hidden;
            margin-top: 27px;
            &:hover {
                img {
                    transform: scale(1.05, 1.05);
                }
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .5s;
            }
            .bg {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
                background-image: url(../images/index_img_16.png);
                background-repeat: no-repeat;
                background-size: cover;
            }
            .title {
                position: absolute;
                z-index: 3;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                left: 0;
                width: 100%;
                box-sizing: border-box;
                padding: 0 15px;
                bottom: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .list-box {
            flex: 1;
            overflow: hidden;
            margin-left: 90px;
            @media (max-width: 1440px) {
                margin-left: 30px;
            }
        }
        .index-news-list {
            li {
                padding: 25px 0 20px;
                border-bottom: 1px dashed #c1c1c1;
            }
            .title {
                font-size: 16px;
                line-height: 20px;
                height: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-bottom: 10px;
                a {
                    color: #000;
                    transition: all .2s;
                    &:hover {
                        color: #f00;
                    }
                }
            }
            .tool {
                font-size: 14px;
                line-height: 26px;
                color: #ababab;
                height: 26px;
                overflow: hidden;
                span {
                    color: #f00;
                }
                a {
                    color: #f00;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .index-news-more {
            display: block;
            transition: all .2s;
            background: #f00;
            color: #fff;
            font-size: 18px;
            width: 220px;
            height: 54px;
            line-height: 54px;
            border-radius: 27px;
            margin-top: 50px;
            text-align: center;
            &:hover {
                opacity: .9;
            }
        }
    }
}