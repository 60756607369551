.team {
    .text {
        width: 940px;
        margin: 0 auto;
        font-size: 15px;
        line-height: 30px;
        color: #555;
        padding: 20px 0px 45px;
    }
    &-list {
        margin: 0 auto;
        width: 404*3px;
        font-size: 0px;
        .li {
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            padding: 0 14px;
            box-sizing: border-box;
            margin-bottom: 25px;
            &-info {
                width: 100%;
                height: 205px;
                background: #fff;
                border-radius: 10px;
                box-shadow: 1px 1px 4px rgba(0, 0, 0, .04);
                transition: all .3s ease;
                &:hover {
                    transform: translate(0, -5px);
                    box-shadow: 1px 1px 25px rgba(0, 0, 0, .04);
                }
                .article {
                    font-size: 13px;
                    color: #939393;
                    line-height: 26px;
                    margin: 0 auto;
                    width: 296px;
                    padding-top: 13px;
                }
                .user {
                    padding-top: 25px;
                    margin: 0 auto;
                    width: 296px;
                    padding-bottom: 10px;
                    border-bottom: 1px dashed rgba(0, 0, 0, .3);
                    .iconfont {
                        float: left;
                        font-size: 20px;
                        border-radius: 50%;
                        background: #f5f5f5;
                        text-align: center;
                        line-height: 46px;
                        height: 46px;
                        width: 46px;
                    }
                    .user-name {
                        padding-left: 10px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 18px;
                        line-height: 46px;
                    }
                }
            }
        }
    }
}
.store {
    &-top {
        display: inline-block;
        margin-bottom: 30px;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        font-size: 0px;
        .input {
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            width: 214px;
            height: 56px;
            background: #fff;
            + .input {
                border-left: 1px solid #e5e5e5;
            }
            &.select {
                position: relative;
                &:after {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translate(0, -50%);
                    content: "";
                    width: 0px;
                    height: 0px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 6px solid #000;
                }
            }
            select {
                cursor: pointer;
                position: relative;
                z-index: 5;
                padding: 0 20px;
                box-sizing: border-box;
                font-size: 15px;
                background: none;
                color: #888;
                width: 100%;
                height: 100%;
                border: 0px;
            }
            input[type='text'] {
                padding: 0 20px;
                box-sizing: border-box;
                font-size: 15px;
                color: #888;
                width: 100%;
                height: 100%;
                border: 0px;
            }
        }
        .btn {
            display: inline-block;
            vertical-align: top;
            width: 124px;
            height: 56px;
            background: $red;
            button {
                width: 100%;
                height: 100%;
                background: none;
                border: 0px;
                cursor: pointer;
                color: #fff;
                font-size: 24px;
            }
            &:hover {
                opacity: .8;
            }
        }
    }
}
.dealer-stores-box {
    float: left;
    border-right: 1px solid #dcdcdc;
    background-color: #fff;
    height: 622px;
    width: 345px;
    position: relative;
}
.dealer-area-map {
    margin-left: 345px;
    padding: 16px;
    background-color: #fff;
    > div {
        width: 100%;
        height: 598px;
    }
}
.dealer-stores-top {
    height: 56px;
    line-height: 56px;
    border-bottom: 1px dashed #dedede;
    padding-left: 20px;
    font-size: 15px;
    color: #646464;
    span {
        font-weight: bold;
        color: #ef8243;
    }
}
.dealer-stores-btns {
    border-top: 1px solid #b7b7b7;
    > div {
        float: left;
        width: 50%;
        height: 50px;
        position: relative;
        color: #343434;
        box-sizing: border-box;
        border-left: 1px solid #e0e0e0;
        cursor: pointer;
        @include transition();
        &:first-child {
            border-left: none;
        }
        &:after {
            position: absolute;
            display: block;
            width: 12px;
            height: 12px;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            content: '';
            top: 50%;
            left: 50%;
        }
    }
}
.dealer-stores-prev {
    background-color: #6a6a6a;
    &:after {
        transform: rotate(-225deg) translate(0, 12px);
    }
    &:hover {
        background-color: darken(#6a6a6a, 5%);
    }
}
.dealer-stores-next {
    background-color: #6a6a6a;
    &:after {
        transform: rotate(-45deg) translate(-6px, -6px);
    }
    &:hover {
        background-color: darken(#6a6a6a, 5%);
    }
}
.dealer-stores-container {
    width: 100%;
    height: 578px;
    .swiper-slide {
        border-bottom: 1px dashed #dedede;
        padding-left: 35px;
        padding-top: 30px;
        box-sizing: border-box;
        cursor: pointer;
        &.on {
            background-color: #f9f9f9;
            .iconfont {
                color: $red;
            }
            .num {
                background-color: $red;
                color: #fff;
            }
        }
    }
    .iconfont {
        float: left;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        text-align: center;
        line-height: 32px;
        font-size: 32px;
        color: #a6a6a6;
    }
    .num {
        float: left;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        text-align: center;
        line-height: 32px;
        background-color: #efefef;
        font-size: 14px;
        color: #333;
    }
    .info {
        margin-left: 44px;
        margin-right: 5px;
        h4, h2 {
            font-size: 18px;
            color: #333;
            line-height: 28px;
            max-height: 56px;
            margin-bottom: 10px;
            overflow: hidden;
        }
        .p {
            font-size: 13px;
            color: #777;
            line-height: 24px;
            max-height: 72px;
            overflow: hidden;
        }
    }
}
.contact-us {
    width: 1300px;
    margin: 0 auto;
    background: #fff;
    .choose-contact {
        width: 100%;
        height: 300px;
        font-size: 0px;
        .li {
            cursor: pointer;
            width: 50%;
            height: 300px;
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;
            transition: all .3s ease;
            + .li {
                border-left: 1px solid #f0f0f0;
            }
            &.on {
                background: #f9f9f9;
            }
            .iconfont {
                font-size: 60px;
                color: #a2a2a2;
                position: relative;
                top: 50%;
                line-height: 60px;
                margin-top: -30px;
                padding-left: 80px;
                padding-right: 36px;
            }
            .text {
                margin-top: 40px;
                line-height: 30px;
                padding-left: 195px;
                font-size: 14px;
                color: #666;
                .title {
                    margin-bottom: 14px;
                    font-size: 22px;
                    color: #333;
                }
            }
        }
    }
    .contact-map {
        display: none;
        padding: 20px;
        box-sizing: border-box;
        &.on {
            display: block;
        }
        .map {
            width: 100%;
            height: 260px;
        }
    }
}
.contact-us-message {
    width: 1300px;
    margin: 0 auto;
    margin-top: 40px;
    i {
        font-size: 36px;
        line-height: 36px;
        color: #666;
        vertical-align: middle;
    }
    span {
        padding-left: 6px;
        color: #555;
        font-size: 26px;
        line-height: 36px;
        vertical-align: middle;
    }
    form {
        font-size: 0px;
        .input-box {
            margin-top: 30px;
            width: 33.33%;
            display: inline-block;
            vertical-align: top;
            .input {
                box-shadow: 1px 1px 4px rgba(0, 0, 0, .04);
                position: relative;
                width: 100%;
                height: 50px;
                background: #fff;
                padding-left: 110px;
                box-sizing: border-box;
                .text {
                    position: absolute;
                    line-height: 50px;
                    left: 20px;
                    top: 0px;
                    font-size: 14px;
                    color: #666;
                }
                input {
                    font-size: 14px;
                    width: 100%;
                    height: 100%;
                    border: 0px;
                }
            }
            + .input-box {
                padding-left: 15px;
                box-sizing: border-box;
            }
        }
        .select-box {
            margin-top: 30px;
            width: 50%;
            display: inline-block;
            vertical-align: top;
            .select {
                box-shadow: 1px 1px 4px rgba(0, 0, 0, .04);
                position: relative;
                width: 100%;
                height: 50px;
                background: #fff;
                padding-left: 150px;
                box-sizing: border-box;
                .text {
                    position: absolute;
                    line-height: 50px;
                    left: 20px;
                    top: 0px;
                    font-size: 14px;
                    color: #666;
                }
                select {
                    font-size: 14px;
                    width: 100%;
                    height: 100%;
                    border: 0px;
                }
            }
            + .select-box {
                padding-left: 15px;
                box-sizing: border-box;
                .select {
                    padding-left: 190px;
                }
            }
        }
        .textarea-box {
            position: relative;
            margin-top: 14px;
            background: #fff;
            width: 100%;
            height: 170px;
            padding: 10px 20px 10px 110px;
            box-sizing: border-box;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, .04);
            .text {
                position: absolute;
                top: 0px;
                left: 0px;
                margin-left: 20px;
                font-size: 14px;
                color: #666;
                line-height: 50px;
            }
            textarea {
                font-size: 14px;
                line-height: 30px;
                width: 100%;
                height: 100%;
                border: 0px;
                resize: none;
            }
        }
        button {
            margin-top: 22px;
            width: 174px;
            height: 38px;
            background: $red;
            border: 0px;
            cursor: pointer;
            color: #fff;
            font-size: 14px;
            &:hover {
                opacity: .8;
            }
        }
        .ver-box {
            font-size: 0;
            margin-top: 15px;
            .box {
                display: inline-block;
                vertical-align: top;
                width: 200px;
                background: #fff;
                height: 50px;
                box-shadow: 1px 1px 4px rgba(0, 0, 0, .04);
                line-height: 50px;
            }
            .text {
                font-size: 14px;
                color: #666;
                padding-left: 20px;
                display: inline-block;
                vertical-align: top;
                margin-right: 5px;
            }
            input {
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                color: #666;
                width: 120px;
                height: 50px;
                background: none;
                border: none;
                appearance: none;
            }
        }
        .ver-img {
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            height: 50px;
            img {
                display: block;
                height: 50px;
                width: auto;
            }
        }
    }
}
.video-box {
    position: relative;
    max-width: 1352px;
    height: 456px;
    min-width: 1300px;
    margin: 0 auto;
    background: #fff url(../images/video-img.jpg) no-repeat center center;
    background-size: cover;
    .video-wp {
        width: 1060px;
        height: 100%;
        position: relative;
        margin: 0 auto;
    }
    .video-btn {
        position: absolute;
        bottom: -19px;
        left: 0px;
        width: 100%;
        height: 38px;
        background: #a0a0a0;
        font-size: 0px;
        .li {
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            text-align: center;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            line-height: 38px;
            position: relative;
            &.on {
                background: $red;
                &:after {
                    content: "";
                    position: absolute;
                    bottom: -10px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: 0;
                    height: 0;
                    border-width: 10px 15px 0px;
                    border-style: solid;
                    border-color: $red transparent transparent; /*灰 透明 透明 */
                }
            }
            + .li {
                border-left: 1px solid #bdbdbd;
                box-sizing: border-box;
            }
        }
    }
    .video {
        display: block;
        position: absolute;
        top: 46px;
        right: 0px;
        width: 625px;
        height: 349px;
        overflow: hidden;
        .video-box {
            width: 100%;
            height: 100%;
            max-width: 100%;
            min-width: 100%;
            &:hover {
                .vjs-big-play-button {
                    background: rgba(0, 0, 0, .2);
                }
            }
        }
        .video-js {
            width: 100%;
            position: relative;
            height: 100%;
            .vjs-big-play-button {
                position: absolute;
                top: auto;
                left: auto;
                bottom: 25px;
                right: 35px;
                border-radius: 50%;
                width: 46px;
                height: 46px;
                background: none;
            }
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
            &:after {
                opacity: .8;
            }
        }
    }
}
.video-list {
    display: none;
    width: 1080px;
    margin: 60px auto 0;
    position: relative;
    font-size: 0;
    &.on {
        display: block;
    }
    .li {
        display: inline-block;
        vertical-align: top;
        padding: 0 10px;
        box-sizing: border-box;
        width: 33.33%;
        margin-bottom: 10px;
        a {
            position: relative;
            width: 100%;
            display: block;
            &:hover {
                .text {
                    color: $red;
                }
            }
        }
        .text {
            transition: all .3s ease;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 50px;
            width: 100%;
            background: rgba(0, 0, 0, .4);
            color: #fff;
            font-size: 14px;
            padding: 5px 70px 10px 20px;
            box-sizing: border-box;
            &:after {
                content: "";
                position: absolute;
                bottom: 14px;
                right: 20px;
                width: 32px;
                height: 32px;
                background: url(../images/player.png);
                background-size: 100% 100%;
            }
        }
        .img {
            width: 100%;
            padding-bottom: (243/340)*100%;
            position: relative;
            img {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.brand-strength {
    .bread {
        padding-top: 30px;
        margin-bottom: 0;
        background-color: #f5f5f5;
    }
    .title {
        margin-bottom: 27px;
        .left {
            font-size: 30px;
            font-weight: bold;
            color: #333;
            display: inline-block;
            vertical-align: middle;
        }
        .right {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            font-size: 30px;
            color: #333;
            margin-left: 4px;
            padding-left: 8px;
            &:before {
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translate(0, -40%);
                content: "";
                width: 4px;
                height: 26px;
                background: $red;
            }
        }
    }
    &-one {
        padding: 60px 20px 80px;
        background: #f5f5f5;
        &-box {
            text-align: center;
            max-width: 1400px;
            min-width: 1260px;
            margin: 0 auto;
            font-size: 0;
            .img {
                display: inline-block;
                vertical-align: middle;
                width: (1260px - 670px);
                text-align: center;
                img {
                    max-width: 100%;
                    height: auto !important;
                }
            }
            .text {
                text-align: left;
                display: inline-block;
                vertical-align: middle;
                width: 670px;
                .article {
                    font-size: 14px;
                    line-height: 32px;
                    color: #777;
                    .title2 {
                        font-size: 24px;
                        margin: 20px 0px;
                    }
                    .li {
                        padding-left: 16px;
                        background: url(../images/brand_icon_1.jpg) no-repeat top left;
                        background-position: 0px 12px;
                    }
                }
            }
        }
    }
    &-two {
        padding: 120px 20px 150px;
        background: #fff url(../images/bg10.jpg) no-repeat center center;
        background-size: cover;
        &-box {
            text-align: center;
            max-width: 1400px;
            min-width: 1260px;
            margin: 0 auto;
            font-size: 0;
            .text {
                text-align: left;
                width: 680px;
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                line-height: 32px;
                color: #777;
                padding-right: 40px;
                box-sizing: border-box;
            }
            .img {
                width: (1260px - 680px);
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                img {
                    max-width: 100%;
                    height: auto !important;
                }
            }
        }
    }
    &-three {
        padding: 70px 20px 80px;
        background: #fff url(../images/bg11.jpg) no-repeat center center;
        background-size: cover;
        &-box {
            max-width: 1260px;
            min-width: 1260px;
            margin: 0 auto;
            .text {
                width: 680px;
                font-size: 14px;
                line-height: 32px;
                color: #777;
                padding-right: 40px;
                box-sizing: border-box;
                .title2 {
                    font-size: 24px;
                    margin: 40px 0 20px;
                }
            }
        }
        &-list {
            margin-top: 40px;
            font-size: 0;
            .li {
                display: inline-block;
                vertical-align: top;
                width: 406px;
                height: 374px;
                background: #fff;
                border-radius: 10px;
                border: 1px solid #dededf;
                + .li {
                    margin-left: 12px;
                }
                .li-title {
                    margin-top: 50px;
                    font-size: 20px;
                    color: #333;
                    text-align: center;
                    i {
                        line-height: 40px;
                        color: $red;
                        font-size: 32px;
                        vertical-align: middle;
                    }
                    span {
                        line-height: 40px;
                        vertical-align: middle;
                    }
                }
                .article {
                    height: 32 * 8px;
                    width: 350px;
                    margin: 25px auto 0;
                    font-size: 14px;
                    line-height: 32px;
                    color: #777;
                }
            }
        }
    }
}
.news-info {
    background-color: #fff;
    border-radius: 10px;
    padding: 60px 0 80px;
    margin-bottom: 25px;
}
.news-title {
    max-width: 1005px;
    margin: 0 auto;
    border-bottom: 1px dashed #e6e6e6;
    text-align: center;
    padding-bottom: 25px;
    h2, h1 {
        font-size: 30px;
        color: #333;
        margin-bottom: 10px;
    }
    .tips {
        font-size: 14px;
        color: #333;
    }
}
.news-bg {
    background: url(../images/bg_icon_1.png) #eff1f3 no-repeat top center;
    background-size: 100%;
}
.news-article {
    padding-top: 30px;
    font-size: 13px;
    line-height: 30px;
    color: #666;
    max-width: 1005px;
    margin: 0 auto;
    img {
        max-width: 100%;
        height: auto !important;
    }
    p {
        margin-bottom: 15px;
    }
}
.news-paged {
    background-color: #fff;
    li {
        float: left;
        border-left: 1px solid #e5e5e5;
        width: 33.333%;
        box-sizing: border-box;
    }
    a {
        display: block;
        text-align: center;
        height: 56px;
        line-height: 56px;
        overflow: hidden;
        font-size: 13px;
        color: #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 0 20px;
        &:hover {
            color: $red;
        }
    }
    &-prev {
        border-left: none !important;
        a:before {
            display: inline-block;
            vertical-align: top;
            height: 56px;
            width: 10px;
            margin-right: 30px;
            background: url(../images/arrow_2.png) left center no-repeat;
            content: '';
        }
    }
    &-list {
        a:before {
            display: inline-block;
            vertical-align: top;
            height: 56px;
            width: 20px;
            margin-right: 20px;
            background: url(../images/icon_4.png) left center no-repeat;
            content: '';
        }
    }
    &-next {
        a:after {
            display: inline-block;
            vertical-align: top;
            height: 56px;
            width: 10px;
            margin-left: 30px;
            background: url(../images/arrow_3.png) right center no-repeat;
            content: '';
        }
    }
}
.article-video {
    max-width: 100%;
    .video-box {
        max-width: 100%;
    }
    .video-js {
        width: 100%;
        padding-bottom: (1080/1920)*100%;
        position: relative;
        height: auto;
        .vjs-poster {
            background-color: #fff;
        }
        .vjs-big-play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
.intro-con {
    padding: 60px 0;
    font-size: 14px;
    line-height: 30px;
    color: #666;
    img {
        max-width: 100%;
        height: auto;
    }
    p {
        margin-bottom: 15px;
    }
}
.intro-top-box {
    background-color: #fff;
    padding: 75px 85px;
    .left {
        float: left;
        width: 750px;
    }
    .right {
        float: right;
    }
    .title {
        position: relative;
        font-size: 34px;
        color: #333;
        line-height: 40px;
        padding-bottom: 15px;
        margin-bottom: 15px;
        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: 27px;
            height: 3px;
            background: #c00000;
        }
    }
    p {
        margin-bottom: 12px;
    }
    .num-box {
        margin-top: 50px;
        color: #346ef1;
        font-size: 20px;
        font-weight: bold;
        line-height: 70px;
        .num {
            display: inline-block;
            vertical-align: top;
            font-size: 60px;
            font-family: Arial;
        }
    }
}
.intro-bottom-box {
    overflow: hidden;
    margin-top: 60px;
    .left {
        float: left;
        width: 314px;
        box-sizing: border-box;
        padding-left: 24px;
        color: #346ef1;
        .num-box {
            margin-bottom: 40px;
            color: #346ef1;
            font-size: 20px;
            font-weight: bold;
            line-height: 70px;
            .num {
                display: inline-block;
                vertical-align: top;
                font-size: 60px;
                font-family: Arial;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .right {
        margin-left: 320px;
    }
}
.team-project-list {
    font-size: 0;
    margin: 0 -25px;
    padding-top: 20px;
    li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        padding: 0 25px;
        margin-bottom: 50px;
        box-sizing: border-box;
        &:nth-child(2) {
            .bg {
                background-color: #188cd5;
            }
        }
        &:nth-child(3) {
            .bg {
                background-color: #e06565;
            }
        }
        &:nth-child(4) {
            .bg {
                background: linear-gradient(-45deg, #fbc05f, #fc973f);
            }
        }
        &:nth-child(5) {
            .bg {
                background: #44c073;
            }
        }
        &:nth-child(6) {
            .bg {
                background: linear-gradient(-45deg, #43a0da, #4383c4);
            }
        }
    }
    .wrapper {
        position: relative;
        height: 428px;
        overflow: hidden;
        box-sizing: border-box;
        color: #fff;
        &.center {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
    .word-box {
        padding-top: 75px;
        width: 280px;
        margin: 0 auto;
        max-width: 100%;
        text-align: center;
        position: relative;
        z-index: 2;
    }
    .big {
        position: relative;
        z-index: 2;
        color: #fff;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
    }
    .icon {
        display: block;
        width: 64px;
        height: 64px;
        margin: 0 auto 10px;
    }
    .t {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 5px;
    }
    .p {
        font-size: 14px;
        line-height: 28px;
    }
}
.team-health {
    width: 100%;
    height: 630px;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .box {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 1330px;
        box-sizing: border-box;
        padding: 40px;
        background-color: #fff;
        z-index: 2;
        margin-left: -665px;
    }
}
.team-balance {
    padding-top: 80px;
}
.team-balance-swiper {
    padding-top: 30px;
    .swiper-pagination {
        position: static;
        padding-top: 30px;
        &-bullet {
            background: #fff;
            opacity: 1;
            &-active {
                background: $red;
            }
        }
    }
    .swiper-slide {
        .img {
            width: 100%;
            height: 283px;
            margin-bottom: 15px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .t {
            text-align: center;
            font-size: 24px;
            line-height: 34px;
            color: #333;
            margin-bottom: 10px;
        }
        .p {
            font-size: 14px;
            line-height: 24px;
            color: #777;
            width: 364px;
            margin: 0 auto;
            max-width: 100%;
            text-align: center;
        }
    }
}