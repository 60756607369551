.esg-article {
    background-color: #fff;
    box-sizing: border-box;
    padding: 50px;
    border-radius: 10px;
    color: #888;
    line-height: 30px;
    @include articleReset(14px, 30px);
    .left {
        float: left;
        width: 480px;
    }
    .right {
        float: right;
    }
    .title {
        font-size: 34px;
        position: relative;
        line-height: 40px;
        padding-bottom: 15px;
        margin-bottom: 15px;
        color: #333;
        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: 27px;
            height: 3px;
            background: $red;
        }
    }
    .big-t {
        font-size: 20px;
        margin-bottom: 15px;
        color: #000;
    }
}
.esg-list {
    font-size: 0;
    padding-top: 30px;
    .item {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;
        &:nth-child(2n+1) {
            padding-right: 10px;
        }
        &:nth-child(2n) {
            padding-left: 10px;
        }
    }
    .wrapper {
        background-color: #fff;
        padding: 50px;
        border-radius: 10px;
    }
    .img {
        width: 100%;
        height: 293px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .scroll-con {
        margin-top: 28px;
        width: 100%;
        position: relative;
        height: 215px;
        text-align: center;
        font-size: 14px;
        line-height: 30px;
        color: #888;
        overflow: hidden;
        .wrap {
            box-sizing: border-box;
            width: 100%;
            padding-right: 15px;
        }
        .t {
            font-size: 20px;
            line-height: 30px;
            color: #000;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 15px;
        }
        //.iScrollVerticalScrollbar {
        //    background-color: #f0f0f0;
        //    border-radius: 3px;
        //}
        .iScrollIndicator {
            background-color: #ccc !important;
        }
    }
}
.esg-health-title {
    text-align: center;
    .t {
        position: relative;
        font-size: 34px;
        color: #333;
        padding-bottom: 15px;
        margin-bottom: 15px;
        &:before {
            position: absolute;
            left: 50%;
            bottom: 0;
            content: '';
            width: 28px;
            height: 3px;
            background: $red;
            margin-left: -14px;
        }
    }
    .p {
        font-size: 14px;
        line-height: 30px;
        color: #888;
    }
}
.esg-health-list {
    background-color: #fff;
    border-radius: 5px;
    padding: 0 100px 30px;
    margin-top: 30px;
    .item {
        display: flex;
        align-items: center;
        padding: 70px 0;
        justify-content: space-between;
        &:nth-child(odd) {
            .img-box {
                &:after {
                    right: -30px;
                }
            }
        }
        &:nth-child(even) {
            flex-direction: row-reverse;
            .img-box {
                &:after {
                    left: -30px;
                }
            }
        }
        &:nth-child(7n+1),
        &:nth-child(7n+5) {
            .img-box {
                &:after {
                    background: #01ae42;
                }
            }
            .t {
                color: #01ae42;
            }
        }
        &:nth-child(7n+2),
        &:nth-child(7n+7) {
            .img-box {
                &:after {
                    background: #ff8113;
                }
            }
            .t {
                color: #ff8113;
            }
        }
        &:nth-child(7n+3),
        &:nth-child(7n+6) {
            .img-box {
                &:after {
                    background: #7c858c;
                }
            }
            .t {
                color: #7c858c;
            }
        }
        &:nth-child(7n+4) {
            .img-box {
                &:after {
                    background: #0059b3;
                }
            }
            .t {
                color: #0059b3;
            }
        }
    }
    .img-box {
        position: relative;
        .img {
            width: 444px;
            height: 271px;
            border-radius: 15px;
            position: relative;
            z-index: 2;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 15px;
            content: '';
            display: block;
            z-index: 1;
            bottom: -30px;
        }
    }
    .info {
        width: 50%;
        box-sizing: border-box;
        padding-left: 15px;
    }
    .t {
        font-size: 30px;
        margin-bottom: 20px;
    }
    .p {
        font-size: 14px;
        line-height: 30px;
        color: #888;
        text-align: justify;
    }
}
.development-swiper {
    margin-top: 40px;
    margin-bottom: 60px;
    .swiper-slide {
        position: relative;
        background-color: #f0f0f0;
        .img {
            img {
                width: 100%;
                display: block;
            }
        }
        .word {
            position: absolute;
            z-index: 2;
            color: #fff;
            left: 70px;
            top: 80px;
            width: 220px;
        }
        .t {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .p {
            font-size: 16px;
            line-height: 32px;
        }
    }
    .swiper-pagination {
        &-bullet {
            opacity: 1;
            background-color: #fff;
            &-active {
                background: $red;
            }
        }
    }
}
.development-page-list {
    font-size: 0;
    margin: 0 -10px;
    li {
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        padding: 0 10px;
        width: 20%;
        margin-bottom: 30px;
    }
    .wrapper {
        position: relative;
        width: 100%;
        height: 390px;
        display: block;
        cursor: pointer;
        &:hover {
            .box {
                width: 258px;
                height: 425px;
            }
            .img {
                height: 369px;
            }
        }
    }
    .box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #e1e1e1;
        background-color: #fff;
        transition: all .4s;
        backface-visibility: hidden;
    }
    .img {
        width: 100%;
        height: 334px;
        transition: all .4s;
        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            transition: all .4s;
        }
    }
    .p {
        height: 54px;
        line-height: 54px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        background: #f7f7f7;
        color: #555;
    }
}
.development-more-btn {
    display: flex;
    cursor: pointer;
    margin: 0 auto;
    background: $red;
    color: #fff;
    align-items: center;
    justify-content: center;
    width: 310px;
    height: 54px;
    font-size: 16px;
    transition: all .2s;
    .up {
        display: none;
    }
    .iconfont {
        transform: rotate(180deg);
        margin-right: 10px;
        transition: all .2s;
    }
    &:hover {
        background: lighten($red, 5%);
    }
    &.on {
        .iconfont {
            transform: rotate(0);
        }
        .up {
            display: block;
        }
        .down {
            display: none;
        }
    }
}