.news-list {
    padding: 0 20px;
    box-sizing: border-box;
    .news-ul {
        margin-bottom: 40px;
    }
    .news-li {
        padding-bottom: 20px;
        display: block;
        width: 100%;
        .wrapper {
            height: 175px;
            position: relative;
            padding: 15px 250px;
            box-sizing: border-box;
            width: 100%;
            display: block;
            background: #fff;
            transition: all .3s ease;
            &:hover {
                transform: translate(0, -5px);
                box-shadow: 0 2px 18px rgba(0, 0, 0, .14);
                .time {
                    background: $red;
                }
                .text {
                    .title {
                        a {
                            color: $red;
                        }
                    }
                }
                .img {
                    img {
                        opacity: .8;
                    }
                }
            }
            .img {
                position: absolute;
                top: 15px;
                left: 15px;
                height: 145px;
                width: 210px;
                display: block;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .time {
                position: absolute;
                right: 30px;
                top: 48px;
                width: 100px;
                height: 86px;
                background: #535353;
                border-top-right-radius: 20px;
                border-bottom-left-radius: 20px;
                color: #fff;
                text-align: center;
                transition: all .3s ease;
                .day {
                    margin-top: 12px;
                    line-height: 36px;
                    font-size: 36px;
                }
                .yy {
                    margin-top: 6px;
                    font-size: 14px;
                }
            }
            .text {
                margin-top: 22px;
                .title {
                    color: #333;
                    font-size: 22px;
                    margin-bottom: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transition: all .3s ease;
                    a {
                        color: #333;
                    }
                }
                .article {
                    font-size: 13px;
                    color: #777;
                    line-height: 30px;
                    height: 60px;
                    overflow: hidden;
                }
            }
            .text-time {
                position: absolute;
                text-align: center;
                font-size: 12px;
                color: #888;
                width: 100px;
                right: 30px;
                bottom: 20px;
                span {
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 10px;
                }
            }
        }
    }
}
.history-box {
    position: relative;
    width: 1360px;
    margin: 60px auto 20px;
    @media screen and (max-width: 1500px) {
        width: 1200px;
    }
    .history-next {
        cursor: pointer;
        position: absolute;
        z-index: 10;
        right: 76px;
        top: 178px;
        width: 25px;
        height: 42px;
        background: url(../images/arrow_5.png);
        background-size: 100% 100%;
        &:hover {
            opacity: .8;
        }
        @media screen and (max-width: 1500px) {
            right: 56px;
            top: 158px;
        }
    }
    .history-prev {
        cursor: pointer;
        position: absolute;
        z-index: 10;
        left: 76px;
        top: 178px;
        width: 25px;
        height: 42px;
        background: url(../images/arrow_4.png);
        background-size: 100% 100%;
        @media screen and (max-width: 1500px) {
            left: 56px;
            top: 158px;
        }
        &:hover {
            opacity: .8;
        }
    }
    .history-box2 {
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .history-swiper {
        width: 160%;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        overflow: hidden;
        .swiper-slide {
            width: 33.33%;
            position: relative;
            padding: 0 6px;
            box-sizing: border-box;
            &.swiper-slide-next {
                .a {
                    &:after {
                        background: none;
                    }
                    .text {
                        opacity: 1;
                    }
                }
            }
            .a {
                display: block;
                position: relative;
                width: 100%;
                padding-bottom: (465/865)*100%;
                &:after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background: rgba(0, 0, 0, .5);
                }
                .text {
                    opacity: .9s;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    .text-box {
                        z-index: 20;
                        position: absolute;
                        bottom: 14px;
                        left: 20px;
                        right: 20px;
                        color: #fff;
                        .year {
                            font-size: 20px;
                            line-height: 30px;
                        }
                        .year-r {
                            font-size: 14px;
                            line-height: 30px;
                        }
                    }
                    &:after {
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        z-index: 10;
                        background: url(../images/history_icon.png);
                        background-size: 100% 100%;
                    }
                }
                .img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    background: #fff none no-repeat center center;
                    background-size: cover;
                }
            }
        }
    }
}
.recommend-con {
    background-color: #fff;
    padding: 25px 0 30px;
    .index-title {
        .t {
            font-size: 32px;
        }
    }
    &.bg-f4 {
        background-color: #f4f4f4;
    }
}
.recommend-hd {
    text-align: center;
    .item {
        display: inline-block;
        vertical-align: middle;
        border-radius: 6px;
        width: 176px;
        height: 70px;
        border: 1px solid #ddd;
        box-sizing: border-box;
        background: #fff;
        font-size: 14px;
        text-align: center;
        margin: 0 5px;
        transition: all .3s ease;
        line-height: 70px;
        cursor: pointer;
        &:hover,
        &.on {
            background-color: #e1e1e1;
        }
    }
}
.recommend-bd {
    .recommend-list {
        display: none;
        &:first-child {
            display: block;
        }
    }
}
.recommend-list {
    font-size: 0;
    padding-top: 30px;
    li {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;
        padding: 0 40px;
        &:nth-child(-n+2) {
            a {
                border-top: none;
            }
        }
        a {
            display: block;
            border-top: 1px dashed #eee;
            padding: 15px 0;
            position: relative;
            &:before {
                position: absolute;
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 100%;
                background-color: $red;
                content: '';
                left: -14px;
                top: 24px;
            }
            &:hover {
                h2 {
                    color: $red;
                }
            }
        }
        h2 {
            font-size: 16px;
            line-height: 24px;
            height: 24px;
            color: #000;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 6px;
        }
        .p {
            font-size: 13px;
            line-height: 30px;
            height: 30px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #777;
            margin-bottom: 5px;
        }
        .time {
            font-size: 12px;
            color: #9f9f9f;
            span {
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
            }
        }
    }
}
.responsibility-topic {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    .img {
        display: block;
        width: 663px;
        height: 458px;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .5s;
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
    .info {
        flex: 1;
        overflow: hidden;
        margin-left: 70px;
    }
    .t {
        font-size: 30px;
        font-weight: bold;
        color: #333;
        padding-bottom: 12px;
        border-bottom: 1px dashed #8e8e8e;
        margin-bottom: 15px;
    }
    .p {
        font-size: 14px;
        line-height: 30px;
        color: #777;
    }
    .more {
        display: block;
        margin-top: 40px;
        width: 260px;
        height: 54px;
        text-align: center;
        line-height: 54px;
        color: #fff;
        background: $red;
        font-size: 16px;
        transition: all .2s;
        &:hover {
            background: lighten($red, 5%);
        }
    }
}
.responsibility-list {
    font-size: 0;
    li {
        display: inline-flex;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;
        padding-left: 15px;
        margin-bottom: 50px;
        align-items: center;
    }
    .img {
        display: block;
        width: 210px;
        height: 145px;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .5s;
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
    .info {
        flex: 1;
        margin-left: 18px;
        overflow: hidden;
        max-width: 350px;
    }
    .t {
        font-size: 20px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
        a {
            color: #333;
            transition: all .2s;
            &:hover {
                color: $red;
            }
        }
    }
    .p {
        font-size: 14px;
        line-height: 28px;
        color: #777;
        height: 56px;
        overflow: hidden;
    }
}