.report-list {
    padding-top: 60px;
    text-align: center;
    font-size: 0px;
    .li {
        display: inline-block;
        padding: 0px 55px;
        a {
            border: 1px solid #e0e0e0;
            display: inline-block;
            vertical-align: top;
            position: relative;
            width: 320px;
            transition: all .3s ease;
            &:hover {
                transform: translate(0, -5px);
                box-shadow: 0 2px 18px rgba(0, 0, 0, .1);
                .text {
                    color: $red;
                }
            }
            .img {
                overflow: hidden;
                height: 432px;
                width: 100%;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                background: #f7f7f7;
                height: 72px;
                font-size: 18px;
                color: #555;
                text-align: center;
                padding: 0 20px;
                box-sizing: border-box;
                transition: all .3s ease;
                p,
                h2 {
                    font-size: 18px;
                    line-height: 24px;
                    max-height: 48px;
                    overflow: hidden;
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }
    }
}
.shop_store_list {
    padding-bottom: 60px;
    max-width: 884px;
    margin: 0 auto;
    font-size: 0px;
    .li {
        margin-bottom: 8px;
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        padding: 0 4px;
        box-sizing: border-box;
        a {
            position: relative;
            background: #fff;
            display: block;
            height: 130px;
            border: 1px solid #d8d8d8;
            width: 100%;
            padding-right: 160px;
            padding-left: 46px;
            box-sizing: border-box;
            transition: all .3s ease;
            &:hover {
                transform: translate(0, -5px);
                box-shadow: 0 2px 18px rgba(0, 0, 0, .14);
            }
            .icon {
                position: absolute;
                top: 35px;
                right: 55px;
                width: 61px;
                height: 62px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 16px;
                color: #666;
            }
        }
    }
}
.vocational_work-swiper {
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
    max-width: 1708px;
    min-width: 1300px;
    margin: 0 auto;
    .vocational_work_box {
        position: relative;
        padding: 0px 54px;
        overflow: hidden;
        box-sizing: border-box;
        .swiper {
            padding: 40px 0px 80px;
            overflow: hidden;
            width: 100%;
        }
        .prev {
            position: absolute;
            left: 0px;
            top: 200px;
            cursor: pointer;
            width: 38px;
            height: 38px;
            background: url(../images/vocational_work_icon_1.png);
            background-size: 100% 100%;
            &:hover {
                opacity: .8;
            }
            @media screen and (max-width: 1500px) {
                top: 180px;
            }
        }
        .next {
            position: absolute;
            right: 0px;
            top: 200px;
            cursor: pointer;
            width: 38px;
            height: 38px;
            background: url(../images/vocational_work_icon_1.png);
            background-size: 100% 100%;
            transform: translate(0, -50%) rotate(180deg);
            &:hover {
                opacity: .8;
            }
            @media screen and (max-width: 1500px) {
                top: 180px;
            }
        }
        .swiper-slide {
            width: 20%;
            padding: 0 22px;
            box-sizing: border-box;
            @media screen and (max-width: 1500px) {
                padding: 0 10px;
            }
            a {
                display: block;
                overflow: hidden;
                border-radius: 8px;
                transition: all .3s ease;
                &:hover {
                    transform: translate(0, -5px);
                    box-shadow: 0 2px 18px rgba(0, 0, 0, .1);
                    .img {
                        opacity: .8;
                    }
                }
            }
            .img {
                position: relative;
                width: 100%;
                padding-bottom: (273/276)*100%;
                background: #fff none no-repeat center center;
                background-size: cover;
                transition: all .3s ease;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: block;
                    top: 0;
                    left: 0;
                }
            }
            .text {
                position: relative;
                height: 80px;
                width: 100%;
                padding: 0 20px;
                box-sizing: border-box;
                background: #fff;
                .p {
                    line-height: 30px;
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                    font-size: 16px;
                    color: #555;
                }
                h2 {
                    display: inline-block;
                    vertical-align: top;
                    line-height: 30px;
                }
            }
        }
    }
}