.fix-right {
    display: none;
    position: fixed;
    z-index: 100;
    right: 0px;
    top: 50%;
    transform: translate(0, -50%);
    .li, .scroll-top {
        display: block;
        cursor: pointer;
        width: 65px;
        height: 69px;
        background: #616161;
        text-align: center;
        border-bottom-left-radius: 20px;
        color: #fff;
        .iconfont {
            padding-top: 10px;
            line-height: 28px;
            font-size: 28px;
        }
        p {
            padding-top: 4px;
            font-size: 12px;
        }
        + .li, + .scroll-top {
            margin-top: 6px;
        }
    }
    .li {
        &:hover {
            background: #888;
        }
    }
    .scroll-top {
        background: #fff;
        color: #666;
        border: 1px solid #dfdfdf;
        box-sizing: border-box;
    }
}
.footer {
    background: #4e4e4e;
    min-width: 1300px;
    padding: 68px 20px 47px;
    box-sizing: border-box;
    &-box {
        margin: 0 auto;
        max-width: 1420px;
    }
    .erma {
        display: inline-block;
        vertical-align: top;
        width: 128px;
        img {
            max-width: 100%;
        }
    }
    .left-text {
        width: 280px;
        display: inline-block;
        vertical-align: top;
        padding-left: 20px;
        .logo {
            display: inline-block;
            width: 180px;
            margin-bottom: 26px;
            img {
                max-width: 100%;
            }
        }
        .p {
            text-align: left;
            font-size: 12px;
            color: #b8b7b7;
            line-height: 22px;
        }
        .color-yellow {
            color: yellow;
        }
        .share-icon {
            font-size: 0;
            text-align: left;
            padding-top: 15px;
            .item {
                display: inline-block;
                vertical-align: top;
                position: relative;
                margin-right: 10px;
                a {
                    width: 45px;
                    height: 45px;
                    display: block;
                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
                &:hover {
                    .icon-ewm {
                        display: block;
                    }
                    a {
                        opacity: .8;
                    }
                }
            }
            .icon-ewm {
                position: absolute;
                top: 46px;
                left: -40px;
                width: 127px;
                display: none;
                img {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    .left {
        float: left;
    }
    .right {
        float: right;
        width: 840px;
        text-align: right;
        font-size: 0;
        @media (max-width: 1330px) {
            width: 820px;
        }
        .ul {
            border-left: 1px solid #464646;
            width: (1/8)*100%;
            display: inline-block;
            vertical-align: top;
            min-height: 250px;
            padding: 0 10px;
            box-sizing: border-box;
            text-align: center;
            &:first-child {
                border-left: 0px;
            }
            .title {
                display: block;
                font-size: 14px;
                color: #fff;
                margin-bottom: 24px;
                &:hover {
                    opacity: .7;
                }
            }
            .li {
                padding: 6px 0px;
                a {
                    color: #b8b7b7;
                    font-size: 12px;
                    line-height: 18px;
                    &:hover {
                        opacity: .7;
                    }
                }
            }
        }
    }
}
.footer-bottom {
    height: 116px;
    width: 100%;
    min-width: 1300px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #4c4c4c;
    color: #b8b7b7;
    text-align: center;
    p {
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
    }
    a {
        color: #b8b7b7;
        &:hover {
            opacity: .8;
        }
    }
}