.head {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    min-width: 1300px;
    padding: 0 20px;
    box-sizing: border-box;
    &-box {
        position: relative;
        margin: 0 auto;
        max-width: 1540px;
    }
    .logo {
        float: left;
        @media (min-width: 1024px) and (max-width: 1500px) {
            height: 70px;
            img {
                display: block;
                width: auto;
                max-height: 100%;
            }
        }
    }
    .head-right {
        float: right;
        margin-top: 28px;
        @media (min-width: 1024px) and (max-width: 1500px) {
            margin: 18px;
        }
    }
    .search {
        z-index: 30;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        line-height: 30px;
        margin: 0px 10px 0px 16px;
        color: #333;
        .icon {
            cursor: pointer;
            font-size: 18px;
        }
        .search-box {
            border: 1px solid #999;
            display: none;
            top: 0px;
            position: absolute;
            right: 0px;
            background: #fff;
            form {
                white-space: nowrap;
            }
            input[type='text'] {
                padding: 0 20px;
                display: inline-block;
                vertical-align: top;
                width: 140px;
                height: 30px;
                background: #fff;
                border: 0px;
                font-size: 16px;
            }
            button {
                cursor: pointer;
                width: 40px;
                height: 30px;
                background: none;
                border: 0px;
                display: inline-block;
                vertical-align: top;
                font-size: 16px;
                &:hover {
                    color: $red;
                }
            }
        }
        &:hover {
            .search-box {
                display: block;
            }
            .icon {
                color: $red;
            }
        }
    }
    .text {
        font-size: 15px;
        line-height: 30px;
        color: #333;
        display: inline-block;
        vertical-align: middle;
        font-weight: bold;
        @media (max-width: 1500px) {
            font-size: 13px;
        }
        // &+.text {
        //     margin-left: 60px;
        //     @media (max-width: 1580px) {
        //         margin-left: 30px;
        //     }
        //     @media (max-width: 1520px) {
        //         margin-left: 10px;
        //     }
        // }
    }
    .language {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        line-height: 30px;
        color: #333;
        padding-left: 14px;
        &:after {
            content: "";
            width: 1px;
            height: 10px;
            background: #b4acab;
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translate(0, -50%);
        }
        &:hover {
            color: $red;
        }
    }
    .tel {
        display: inline-block;
        vertical-align: middle;
        margin-left: 40px;
        .icon {
            display: inline-block;
            img {
                display: inline-block;
                width: 30px;
                height: 30px;
            }
        }
        span {
            font-size: 15px;
            line-height: 30px;
            color: #333;
            font-weight: bold;
            @media (max-width: 1500px) {
                font-size: 13px;
            }
        }
    }
    .nav {
        position: relative;
        z-index: 1;
        font-size: 0;
        display: inline-block;
        vertical-align: middle;
        li {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            + li {
                &:after {
                    content: "";
                    width: 1px;
                    height: 10px;
                    background: #b4acab;
                    position: absolute;
                    top: 50%;
                    left: 0px;
                    transform: translate(0, -50%);
                }
            }
            &:hover {
                .nav-a {
                    color: $red;
                }
            }
            .nav2-box {
                position: relative;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                display: none;
            }
            .nav2 {
                padding-top: 10px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                .nav2-a {
                    line-height: 46px;
                    display: block;
                    padding: 0 40px;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .nav2-li {
                    border: 1px solid #ddd;
                    box-sizing: border-box;
                    display: block;
                    font-size: 14px;
                    color: #333;
                    line-height: 46px;
                    background: #fff;
                    transition: all .3s ease;
                    position: relative;
                    &:after {
                        transition: all .3s ease;
                        opacity: 0;
                        content: "";
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 2px;
                        background: $red;
                    }
                    + .nav2-li {
                        border-top: 0;
                    }
                    .nav2-sub {
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: translateX(100%);
                        background-color: #ebeef0;
                        display: none;
                        .nav2-a {
                            position: relative;
                            box-sizing: border-box;
                            transition: all .3s ease;
                            border-bottom: 1px solid #ddd;
                            &:after {
                                transition: all .3s ease;
                                opacity: 0;
                                content: "";
                                width: 100%;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                height: 2px;
                                background: $red;
                            }
                            &:hover {
                                color: $red;
                                &:after {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    &:hover {
                        background: #ebeef0;
                        > .nav2-a {
                            color: $red;
                        }
                        .nav2-sub {
                            display: block;
                        }
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
            .nav-a {
                display: block;
                position: relative;
                z-index: 6;
                line-height: 30px;
                padding: 0 20px;
                font-size: 14px;
                color: #333;
                font-weight: bold;
                @media (max-width: 1440px) {
                    padding: 0 15px;
                }
                @media (max-width: 1360px) {
                    padding: 0 10px;
                }
                &.on {
                    color: $red;
                }
            }
        }
    }
    &.is-index {
        position: relative;
        background-color: #fff;
    }
}