.knowledge-con {
    background-color: #f0f0f0;
    padding: 20px 0 60px;
}
.knowledge-main {
    display: flex;
    padding-top: 10px;
    &-left {
        flex: 1;
        overflow: hidden;
        margin-right: 20px;
        .news-info {
            margin-top: 40px;
            padding-right: 50px;
            padding-left: 50px;
        }
    }
    &-right {
        width: 337px;
        padding-top: 40px;
        &.no-pt {
            padding-top: 0;
        }
        &-title {
            font-size: 18px;
            color: #333;
            font-weight: bold;
            margin-bottom: 15px;
            span {
                color: $red;
            }
        }
    }
    &-classify {
        .item {
            display: flex;
        }
        .word {
            margin-right: 10px;
            font-size: 15px;
            color: #333;
            line-height: 30px;
            img {
                display: inline-block;
                vertical-align: top;
                margin-top: 9px;
            }
        }
        .link {
            flex: 1;
            overflow: hidden;
            a {
                display: inline-block;
                vertical-align: top;
                font-size: 15px;
                color: #333;
                margin-right: 30px;
                line-height: 30px;
                margin-bottom: 5px;
                &.on,
                &:hover {
                    color: $red;
                }
            }
        }
    }
}
.knowledge-list {
    padding-top: 10px;
    padding-bottom: 10px;
    li {
        margin-bottom: 20px;
        a {
            display: flex;
            box-sizing: border-box;
            background-color: #fff;
            padding: 15px;
            transition: all .2s;
            align-items: center;
            &:hover {
                transform: translateY(-5px);
                .title {
                    color: $red;
                }
            }
        }
        .img {
            width: 210px;
            height: 145px;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .info {
            flex: 1;
            overflow: hidden;
            margin: 0 25px;
        }
        .title {
            font-size: 22px;
            color: #333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 20px;
        }
        .p {
            font-size: 14px;
            line-height: 30px;
            height: 60px;
            overflow: hidden;
            color: #777;
        }
    }
}
.knowledge-card {
    background-color: #fff;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 25px 30px;
    .title {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #333;
        padding-bottom: 20px;
        border-bottom: 1px dashed #d5d5d5;
        margin-bottom: 20px;
        img {
            margin-right: 5px;
        }
    }
    .list {
        li {
            margin-bottom: 25px;
            a {
                display: block;
                position: relative;
                padding-left: 16px;
                font-size: 14px;
                line-height: 20px;
                color: #333;
                transition: all .2s;
                &:before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    content: '';
                    border: 1px solid $red;
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                }
                &:hover {
                    color: $red;
                }
            }
        }
    }
}