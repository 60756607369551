.special-page {
    padding-top: 125px;
    background-color: #fff;
}
.special-menu {
    position: relative;
    height: 56px;
    z-index: 10;
    &.fixed {
        .wp {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 909;
        }
    }
    .wp {
        font-size: 0;
        text-align: center;
        width: 100%;
        z-index: 990;
        background: linear-gradient(to right, #162b61, #601975);
    }
    .item {
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: 280px;
        line-height: 56px;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        + .item:after {
            position: absolute;
            display: block;
            left: 0;
            top: 50%;
            width: 1px;
            height: 8px;
            margin-top: -4px;
            background: rgba(#fff, .4);
            content: '';
        }
    }
}
.events-top {
    width: 100%;
    height: 445px;
    background-image: url(../images/special_bg1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    .top {
        text-align: center;
        color: #fff;
        margin-top: 70px;
        margin-bottom: 50px;
        .cn {
            font-size: 36px;
            margin-bottom: 5px;
        }
        .en {
            font-family: Arial;
            font-size: 16px;
        }
    }
}
.events-swiper {
    width: 1330px;
    position: relative;
    margin: 0 auto;
    .events-prev,
    .events-next {
        position: absolute;
        top: 50%;
        color: #fff;
        font-size: 30px;
        line-height: 40px;
        margin-top: -20px;
        cursor: pointer;
        z-index: 5;
    }
    .events-prev {
        left: -30px;
        transform: rotate(180deg);
    }
    .events-next {
        right: -30px;
    }
    .swiper-slide {
        width: 210px;
        position: relative;
        cursor: pointer;
        .dot {
            width: 142px;
            height: 142px;
            margin: 0 auto;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
            position: relative;
        }
        .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            box-sizing: border-box;
            border: 5px solid #fff;
            background: linear-gradient(45deg, #4e1d6d, #aa57b6);
            border-radius: 100%;
            opacity: 0;
        }
        .num {
            display: flex;
            flex: 1;
            box-sizing: border-box;
            border: 1px dashed #fff;
            text-align: center;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 24px;
            color: #fff;
            font-family: Arial;
            position: relative;
            z-index: 2;
            border-radius: 100%;
            width: 100%;
            height: 100%;
        }
        &.on {
            .bg {
                opacity: 1;
            }
        }
        + .swiper-slide:before {
            position: absolute;
            width: 32px;
            height: 5px;
            background-image: url(../images/special_dot_1.png);
            top: 50%;
            margin-top: -2px;
            left: -16px;
            z-index: 5;
            content: '';
        }
    }
}
.events-bottom {
    padding: 80px 0 90px;
}
.events-con {
    width: 1330px;
    margin: 0 auto;
    position: relative;
    height: 537px;
    background-position: center center;
    background-repeat: no-repeat;
    .events-bd {
        position: absolute;
        z-index: 2;
        left: 537px;
        top: 113px;
        .box {
            display: none;
            &:first-child {
                display: block;
            }
        }
        .img {
            width: 526px;
            height: 311px;
            overflow: hidden;
            border-radius: 20px;
            border: 5px solid #fff;
            margin-bottom: 15px;
            display: block;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .title {
            text-align: center;
            color: #fff;
            font-size: 16px;
            display: block;
            max-width: 536px;
        }
    }
}
.special-active {
    &-con {
        padding: 70px 0;
        background: #f5f5f5;
        .top {
            text-align: center;
            .cn {
                font-size: 36px;
                font-weight: bold;
                color: #c11545;
                margin-bottom: 5px;
            }
            .en {
                font-family: Arial;
                font-size: 16px;
                color: #666;
            }
        }
    }
    &-swiper {
        position: relative;
        width: 1330px;
        margin: 0 auto;
        .special-active-prev,
        .special-active-next {
            position: absolute;
            top: 50%;
            font-size: 32px;
            color: #000;
            cursor: pointer;
            line-height: 40px;
            margin-top: -20px;
            transition: all .2s;
            z-index: 5;
            &:hover {
                color: #c30d23;
            }
        }
        .special-active-prev {
            left: 0;
            transform: rotate(180deg);
        }
        .special-active-next {
            right: 0;
        }
        .swiper-container {
            .swiper-slide {
                background-color: #f5f5f5;
                padding: 30px 0;
                .wp {
                    position: relative;
                    background-color: #fff;
                    width: 1160px;
                    margin: 0 auto;
                    box-shadow: 0 10px 20px rgba(#000, .1);
                    box-sizing: border-box;
                    padding: 25px;
                    display: flex;
                    align-items: center;
                    background-image: url(../images/special_bg3.jpg);
                    background-position: right bottom;
                    background-repeat: no-repeat;
                }
                .img {
                    display: block;
                    width: 552px;
                    height: 370px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    &:hover {
                        opacity: .9;
                    }
                }
                .info {
                    flex: 1;
                    margin: 0 25px 0 60px;
                    overflow: hidden;
                }
                .t {
                    font-size: 24px;
                    font-weight: bold;
                    color: #333;
                    margin-bottom: 15px;
                }
                .p {
                    font-size: 14px;
                    line-height: 28px;
                    color: #888;
                    overflow: hidden;
                    max-height: 28px * 6;
                    margin-bottom: 20px;
                }
                .arrow {
                    display: block;
                    border: 2px solid #e0594c;
                    color: #e0594c;
                    text-align: center;
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    line-height: 24px;
                    font-size: 12px;
                    transition: all .2s;
                    &:hover {
                        background: #e0594c;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.special-publicity {
    padding: 70px 0 95px;
    background-image: url(../images/special_bg4.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    .title {
        text-align: center;
        margin-bottom: 40px;
        color: #fff;
        .cn {
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .en {
            font-family: Arial;
            font-size: 16px;
        }
    }
    .topic {
        display: flex;
        align-items: center;
        width: 1330px;
        margin: 0 auto 40px;
        .img {
            padding: 18px;
            background-color: #fff;
            width: 738px;
            height: 420px;
            position: relative;
            margin-right: -64px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &:hover {
                opacity: .9;
            }
        }
        .info {
            flex: 1;
            overflow: hidden;
            position: relative;
            z-index: 2;
        }
        .wp {
            background-color: #fff;
            padding: 60px 82px;
            box-sizing: border-box;
            width: 100%;
        }
        .t {
            font-size: 22px;
            font-weight: bold;
            color: #333;
            margin-bottom: 15px;
        }
        .p {
            font-size: 14px;
            line-height: 30px;
            color: #666;
            margin-bottom: 10px;
        }
        .arrow {
            display: block;
            border: 2px solid #e0594c;
            text-align: center;
            width: 25px;
            height: 25px;
            line-height: 25px;
            border-radius: 100%;
            font-size: 12px;
            color: #e0594c;
            transition: all .2s;
            &:hover {
                background-color: #e0594c;
                color: #fff;
            }
        }
    }
    .swiper {
        position: relative;
        width: 1330px;
        margin: 0 auto;
        .publicity-prev,
        .publicity-next {
            color: #fff;
            font-size: 32px;
            position: absolute;
            top: 50%;
            line-height: 40px;
            margin-top: -20px;
            z-index: 5;
            cursor: pointer;
            transition: all .2s;
            &:hover {
                color: #000;
            }
        }
        .publicity-prev {
            transform: rotate(180deg);
            left: -60px;
        }
        .publicity-next {
            right: -60px;
        }
        .swiper-slide {
            width: 417px;
            .wp {
                display: block;
                background-color: #fff;
                padding: 20px;
                width: 100%;
                box-sizing: border-box;
                &:hover {
                    .t {
                        color: #e0594c;
                    }
                }
            }
            .img {
                width: 100%;
                height: 207px;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .info {
                height: 90px;
                box-sizing: border-box;
                padding-top: 20px;
                text-align: center;
            }
            .t {
                font-size: 20px;
                font-weight: bold;
                color: #333;
                line-height: 32px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                transition: all .2s;
            }
            .p {
                font-size: 14px;
                line-height: 28px;
                color: #666;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
.special-video {
    padding: 70px 0;
    background-color: #f5f5f5;
    .title {
        text-align: center;
        margin-bottom: 30px;
        .cn {
            font-size: 36px;
            font-weight: bold;
            color: #c11545;
            margin-bottom: 5px;
        }
        .en {
            font-family: Arial;
            font-size: 16px;
            color: #666;
        }
    }
    &-con {
        width: 1330px;
        margin: 0 auto;
        padding-bottom: 140px;
        background-image: url(../images/special_bg5.jpg);
        background-position: center bottom;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        .video-box {
            width: 825px;
            height: 555px;
            min-width: 0;
            background: none;
            margin: 0;
            video,
            .video-js {
                width: 100%;
                height: 100%;
            }
            .video-js .vjs-big-play-button {
                right: 20px;
                top: auto;
                bottom: 20px;
                left: auto;
            }
        }
        .video-swiper {
            width: 238px;
            margin-left: 10px;
            .swiper-container {
                width: 100%;
                height: 494px;
                margin-bottom: 6px;
            }
            .swiper-slide {
                position: relative;
                cursor: pointer;
                .img {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    &:after {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: linear-gradient(to bottom, transparent, rgba(#000, .8));
                        content: '';
                        z-index: 2;
                    }
                }
                .p {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    font-size: 16px;
                    font-weight: bold;
                    color: #fff;
                    text-align: center;
                    bottom: 10px;
                    z-index: 2;
                }
            }
            .btn-list {
                font-size: 0;
                .btn {
                    display: inline-block;
                    vertical-align: top;
                    width: 50%;
                    height: 53px;
                    line-height: 53px;
                    text-align: center;
                    color: #fff;
                    background: #e0594c;
                    font-weight: bold;
                    cursor: pointer;
                    font-size: 20px;
                    box-sizing: border-box;
                    + .btn {
                        border-left: 1px solid rgba(#fff, .2);
                    }
                    &:hover {
                        background: lighten(#e0594c, 5%);
                    }
                }
                .video-prev {
                    transform: rotate(180deg);
                }
            }
        }
    }
}